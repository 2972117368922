export default class Calculate {
  static fotRemained(fot, FOT_FIX) {
    return Number(Number(fot) - Number(FOT_FIX));
  }
  static sumFot(fot, countEmployees) {
    return Number(Number(fot) * Number(countEmployees));
  }
  static hoursInMonth(hours, days) {
    return Number(Number(hours) * Number(days));
  }
  static reglament(fot) {

    if (Number(fot) <= 54000) {
      return 19242;
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return Number(Number(fot) / 2);
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return Number(Number(fot) * 0.6);
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return Number(Number(fot) * 0.7);
    }
  }
  static reglamentRemain(reglament, fotFix) {
    return Number(Number(reglament) - Number(fotFix));
  }

  static calcNdfl(fot, NDFL) {
    if (fot <= 54000) {
      return Number(25500 * Number(NDFL));
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return (Number(Number(fot) / 2)) * Number(NDFL);
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return (Number(Number(fot) * 0.6)) * Number(NDFL);
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return (Number(Number(fot) * 0.7)) * Number(NDFL);
    }
  }
  static calcSalaries(reglament, NDFL) {
    return Number(Number(reglament) - Number(NDFL));
  }

  static calcFotFull(fot, ndfl) {
    return Number(Number(fot) + Number(ndfl));
  }

  static reglamentTD(fot) {
    if (Number(fot) <= 54000) {
      return 25500;
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return Number(Number(fot) / 2);
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return Number(Number(fot) * 0.6);
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return Number(Number(fot) * 0.7);
    }
  }
  static calcManager(fot, ndfl) {
    if (Number(fot) <= 54000) {
      return Number(Number(fot) + Number(ndfl)) - 25500;
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return Number(Number(fot) + Number(ndfl) - Number(fot) / 2);
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return Number(Number(fot) + Number(ndfl) - Number(fot) * 0.6);
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return Number(Number(fot) + Number(ndfl) - Number(fot) * 0.7);
    }

    // return Number(Number(fot) + Number(ndfl)) - Number(reglament);
  }
  static costHoursEmploy(fotFull, hoursInMonth) {
    return Number(Number(fotFull) / Number(hoursInMonth));
  }
  static calcPfr(reglament, proc) {
    return Number(Number(reglament) * Number(proc));
  }
  static calcFfomc(reglament, ffomc) {
    return Number(Number(reglament) * Number(ffomc));
  }
  static calcFss(reglament, who) {
    let fss = 0;
    if (who == "ПАТЕНТ") {
      fss = 0.018;
    } else {
      fss = 0.029;
    }
    return Number(Number(reglament) * Number(fss));
  }
  static calcFssOutstaff(reglament, insurance) {
    return Number(Number(reglament) * Number(insurance));
  }

  static calcFssTrauma(fot, fss) {
    if (Number(fot) <= 54000) {
      return 25500 * (fss/100);
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return Number((Number(fot) / 2) *(fss/100));
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return Number((Number(fot) * 0.6) * (fss/100));
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return Number((Number(fot) * 0.7) * (fss/100));
    }
  }

  static calcNewFssTrauma(reglament, ndfl) {
    return ((Number(reglament) - Number(ndfl)) / 100 * 0.2);
  }
  static calcTax(pfr, pfr2, fssC, fssTrauma, ffomc, ffomc2, who) {
    let ffomc1Local = 0;
    let ffomc2Local = 0;
    if (who == "ПАТЕНТ") {
      ffomc1Local = 0;
      ffomc2Local = 0;
    } else {
      ffomc1Local = ffomc;
      ffomc2Local = ffomc2;
    }
    return Number(
      Number(pfr) +
        Number(pfr2) +
        Number(fssC) +
        Number(fssTrauma) +
        Number(ffomc1Local) +
        Number(ffomc2Local)
    );
  }
  static calcTaxOutstaff(
    pfrRemainder,
    pfrFix,
    fssInsurance,
    fssTrauma,
    ffomc,
    ffomc2
  ) {
    return Number(
      Number(pfrRemainder) +
        Number(pfrFix) +
        Number(fssInsurance) +
        Number(fssTrauma) +
        Number(ffomc) +
        Number(ffomc2)
    );
  }
  static calcSumWithoutNdc(fotFull, tax, outsource) {
    return Number(Number(fotFull) + Number(tax) + Number(outsource));
  }
  static calcSumWithoutNdcCom(fotFull, tax, outsource, commision) {
    return Number(
      Number(fotFull) + Number(tax) + Number(outsource) + Number(commision)
    );
  }
  static calcNdc(sumWithoutNdc) {
    return Number(Number(sumWithoutNdc) * Number(0.2));
  }
  static calcSumAndNdc(sumWithoutNdc, ndc) {
    return Number(Number(sumWithoutNdc) + Number(ndc));
  }
  static sumEmploys(countEmployees, data) {
    return Number(Number(countEmployees) * Number(data));
  }
//fotFull, ndfl, reglament, fssTrauma, outsource, manager
  static calcNewSumWithoutNdc(fot, fotFull, ndfl, reglament, fssTrauma, outsource, manager) {
  //    return ( ((Number(reglament)*0.3) + ((Number(fotFull) - Number(ndfl) - Number(reglament))*0.15) + Number(fssTrauma) + Number(outsource) + (Number(fotFull) - Number(ndfl)) + (Number(manager) * 0.15)) );
  let fotFull1 = fotFull;
  let ndfl1 = ndfl;
  let reglament1 = reglament;
  let fssTrauma1 = fssTrauma;
  let outsource1 = outsource;
  let manager1 = manager;

    let sum = fotFull1 + ndfl1 + reglament1 + fssTrauma1 + outsource1 + manager1;
    sum;



    if (Number(fot) <= 54000) {
      return (Number(fotFull1) + (19242*0.3 + ((25500-19242)*0.15)) + Number(fssTrauma1) + Number(outsource1) + (Number(manager1) * 0.15));
    }
    if (Number(fot) >= 54000 && Number(fot) <= 75000) {
      return (Number(fotFull1) + (19242*0.3 + ((Number(fot)/2 - 19242)*0.15)) + Number(fssTrauma1) + Number(outsource1) + (Number(manager1) * 0.15));
    }
    if (Number(fot) >= 75000 && Number(fot) <= 100000) {
      return (Number(fotFull1) + (19242*0.3 + ((Number(fot) * 0.6 - 19242)*0.15)) + Number(fssTrauma) + Number(outsource) + (Number(manager1) * 0.15));
    }
    if (
      (Number(fot) >= 100000 && Number(fot) <= 150000) ||
      Number(fot) > 150000
    ) {
      return (Number(fotFull1) + (19242*0.3 + ((Number(fot) * 0.7 - 19242)*0.15)) + Number(fssTrauma1) + Number(outsource1) + (Number(manager1) * 0.15));
    }

  }


}
//Налог база (19242*0.3 + ((Number(reglament) - 19242)*0.15) + Number(fssTrauma) )
// ЕНП Number(reglament)*0.3 + ((Number(fotFull) - Number(ndfl) - Number(reglament))*0.15)