<template>
  <table class="tg">
    <thead>
      <tr>
        <th class="tg-vhtn">Наименование юр. лица</th>
        <th class="tg-vhtn">Наименование проекта</th>
        <th class="tg-vhtn">Сфера деятельности</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-vhtn">
          <p>{{ name }}</p>
        </td>
        <td class="tg-vhtn">
          <p>{{ project }}</p>
        </td>
        <td class="tg-vhtn">
          <p>{{ fieldOfActivity }}</p>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    name: String,
    project: String,
    fieldOfActivity: String,
  },
};
</script>

<style scoped>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 1250px;
  margin: 0 auto 30px;
  width: 100%;
}
.tg td {
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  padding: 20px 10px;
  font-size: 16px;
  word-break: normal;
}
.tg th {
  border-style: solid;
  border-width: 0px;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  padding: 20px 10px;
  word-break: normal;
  font-size: 16px;
  font-weight: 600;
  overflow-wrap: break-word;
  word-wrap: normal;
}
.tg .tg-vhtn {
  background-color: #ffffff;
  font-family: "Montserrat", sans-serif;
  border-color: #000000;
  font-size: 16px;
  text-align: center;
  overflow-wrap: break-word;
  vertical-align: middle;
}
</style>
