<template>
  <label class="input_calc">
    <Transition name="slide-fade">
      <span class="error error-mrot" v-if="this.rf <= 25499 && !isDisableFotRf"
        >Внимание, вы указали ЗП меньше МРОТ. <br /><br />Минимальная заработная плата по Санкт-Петербургу 25 500 рублей.</span
      ></Transition
    >
    <span>РФ</span>
    <input
      :disabled="isDisableFotRf"
      type="number"
      min="15279"
      v-model="rf"
      @input="updateValue"
      required
      placeholder="0"
      class="wage-level__patent"
      id="inpFot__rf"
    />

  </label>
</template>

<script>
export default {
  props: {
    isDisableFotRf: {
      type: Boolean,
    },
  },

  data() {
    return {
      rf: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateFotRf", this.rf);
    },
    resetValue() {
      this.rf = 0;
      this.$emit("updateFotRf", this.rf);
    },
  },
};
</script>

<style></style>
