<template>
  <div class="left_block" style="border: none;">
    <div class="left_block__inner">
      <h3>{{ heading }}</h3>
      <div class="checkbox_wrapper wage-level__wrapper amound " style="display: grid;grid-template-rows: 1fr 1fr 1fr; justify-content: center;">
        <FotPatentVue
          @updateFotPatent="updateFotPatent"
          :isDisableFotPatent="isDisableFot.isDisableFotPatent"
          ref="fotPatent"
        />
        <FotEaeuVue
          @updateFotEaeu="updateFotEaeu"
          :isDisableFotEaeu="isDisableFot.isDisableFotEaeu"
          ref="fotEaeu"
        />
        <ForRfVue
          @updateFotRf="updateFotRf"
          :isDisableFotRf="isDisableFot.isDisableFotRf"
          ref="fotRf"
        />
      </div>
      <div class="important_info">
        Укажите ЗП, которую мы выплачиваем сотруднику до вычета налогов.
      </div>
    </div>
  </div>
</template>

<script>
import FotEaeuVue from "./Fot-eaeu.vue";
import FotPatentVue from "./Fot-patent.vue";
import ForRfVue from "./Fot-rf.vue";

export default {
  props: {
    isDisableFot: {
      type: Object,
    },
    heading: {
      type: String,
    },
  },

  components: {
    FotEaeuVue,
    FotPatentVue,
    ForRfVue,
  },

  data() {
    return {
      fot: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
    };
  },

  // methods: {
  //   updateFotPatent(fot) {
  //       this.fot.patent = fot;
  //       this.$emit("upFot", this.fot);
  //   },
  //   updateFotEaeu(fot) {
  //       this.fot.eaeu = fot;
  //       this.$emit("upFot", this.fot);
  //   },
  //   updateFotRf(fot) {
  //       this.fot.rf = fot;
  //       this.$emit("upFot", this.fot);
  //   },

  methods: {
    updateFotPatent(fot) {
      if (fot >= 15279) {
        this.fot.patent = fot;
        this.$emit("upFot", this.fot);
      }else{
        this.fot.patent = 15279;
      this.$emit("upFot", this.fot);
      }
      
    },
    updateFotEaeu(fot) {
      if (fot >= 15279) {
        this.fot.eaeu = fot;
        this.$emit("upFot", this.fot);
      }else{
        this.fot.eaeu = 15279;
        this.$emit("upFot", this.fot);
      }
    },
    updateFotRf(fot) {
      if (fot >= 15279) {
        this.fot.rf = fot;
        this.$emit("upFot", this.fot);
      }else{
        this.fot.rf = 15279;
        this.$emit("upFot", this.fot);
      }
    },
    resetValues() {
      this.$refs.fotPatent.resetValue();
      this.$refs.fotEaeu.resetValue();
      this.$refs.fotRf.resetValue();
    },
  },
};
</script>

<style></style>
