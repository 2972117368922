<template>
  <div v-for="table in tablesList" :key="table">
    <table-outsource-vue
      v-for="curTable in table.proffestionEmploy"
      :key="curTable"
      :tableInfo="curTable"
      :outsource="outsource"
      :fss="fss"
      ref="tableOutsource"
    />
  </div>
</template>

<script>
import TableOutsourceVue from "./TableOutsource.vue";

export default {
  props: { tablesList: Array, outsource: Number, fss: Number },
  data() {
    return {
      professionList: [],
    };
  },

  components: {
    TableOutsourceVue,
  },
  methods: {},
};
</script>

<style></style>
