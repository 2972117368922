<template>
  <span class="error error-mrot"
    >Добавьте хотя-бы одного сотрудника, для произведения расчеты
  </span>
</template>

<script>
export default {};
</script>

<style></style>
