<template>
  <header
    class="calculate_block header"
    :class="{ 'outsource-bg': bgColorOusource }"
  >
    <a href="https://dpcost.ru/" class="btn-back">Назад</a>
    <h2>{{ heading }}</h2>
    <div></div>
  </header>
</template>

<script>
export default {
  name: "HeaderVue",
  props: { heading: String },
  data() {
    return {
      bgColorOusource: true,
    };
  },

  mounted() {
    this.$nextTick(function () {
      if (this.heading == "Калькулятор аутстаффинг") {
        this.bgColorOusource = false;
      } else {
        this.bgColorOusource = true;
      }
    });
  },
};
</script>

<style>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  background: #0e6fe0;
}
.btn-back {
  font-size: 19px;
  text-align: center;
  color: #ffffff;
  background: transparent;
  padding: 5px;
  /* text-transform: uppercase; */
  font-weight: 600;
  letter-spacing: 1px;
  outline: none;
  border: none;
}

.outsource-bg {
  background: #07baf2;
}
</style>
.outstaff-bg { background: #0e6fe0; }
