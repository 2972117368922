<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>Профессия</h3>
      <div class="checkbox_wrapper row">
        <label class="input_calc">
          <input
            type="string"
            v-model="profession"
            @input="updateValue"
            required
            class="input-name"
            id="proffessionInput"
            placeholder="Грузчик, кладовщик, слесарь.."
          />
          <span class="error"></span>
        </label>
      </div>
      <div class="important_info"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      profession: "",
    };
  },

  methods: {
    async focusProffessionInput() {
      const input = document.getElementById("proffessionInput");
      let inputYonTop = input.getBoundingClientRect().y;
      window.scrollTo({
        top: inputYonTop,
        left: 0,
        behavior: "smooth",
      });
      // input.focus();
    },

    updateValue() {
      this.$emit("upProfession", this.profession);
    },
    resetInput() {
      this.profession = "";
      this.$emit("upProfession", this.profession);
    },
  },
};
</script>

<style></style>
