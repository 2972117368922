<template>
  <label class="input_calc">
    <span>РФ</span>
    <input
      type="number"
      min="0"
      v-model="countRf"
      @input="updateValue"
      required
      placeholder="0"
      class="sum-staff__rf"
    />
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      countRf: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateRf", this.countRf);
    },
    initialValueOutsource() {
      this.countRf = 0;
      this.$emit("updateRf", this.countRf);
    },
    resetValueOutsource() {
      this.countRf = 0;
      this.$emit("updateRf", this.countRf);
    },
    resetValue() {
      this.countRf = 0;
      this.$emit("updateRf", this.countRf);
    },
  },
};
</script>

<style></style>
