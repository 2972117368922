<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>Аутстаффинг</h3>
      <div class="checkbox_wrapper amound" style="display: grid; grid-template-rows: 1fr 1fr 1fr; justify-content: center;">
        <outstaffing-patent-vue
          :isDisableFotPatent="isDisableFot.isDisableFotPatent"
          @updatePatent="updateOutstaffingPatent"
          ref="outstaffingPatent"
        />
        <outstaffing-eaeu-vue
          :isDisableFotEaeu="isDisableFot.isDisableFotEaeu"
          @updateEaeu="updateOutstaffingEaeu"
          ref="outstaffingEaeu"
        />
        <outstaffing-rf-vue
          :isDisableFotRf="isDisableFot.isDisableFotRf"
          @updateRf="updateOutstaffingRf"
          ref="outstaffingRf"
        />
      </div>
      <div class="important_info"></div>
    </div>
  </div>
</template>

<script>
import OutstaffingEaeuVue from "./Outstaffing-eaeu.vue";
import OutstaffingPatentVue from "./Outstaffing-patent.vue";
import OutstaffingRfVue from "./Outstaffing-rf.vue";

export default {
  name: "OutstaffingVue",
  props: {
    isDisableFot: {
      type: Object,
    },
  },
  components: {
    OutstaffingEaeuVue,
    OutstaffingPatentVue,
    OutstaffingRfVue,
  },

  data() {
    return {
      outstaffing: {
        countPatent: 0,
        countEaeu: 0,
        countRf: 0,
      },
    };
  },

  methods: {
    updateOutstaffingPatent(outstaffing) {
      this.outstaffing.countPatent = outstaffing;
      this.$emit("upOutstaffing", this.outstaffing);
    },
    updateOutstaffingEaeu(outstaffing) {
      this.outstaffing.countEaeu = outstaffing;
      this.$emit("upOutstaffing", this.outstaffing);
    },
    updateOutstaffingRf(outstaffing) {
      this.outstaffing.countRf = outstaffing;
      this.$emit("upOutstaffing", this.outstaffing);
    },

    resetValues() {
      this.$refs.outstaffingPatent.resetValue();
      this.$refs.outstaffingEaeu.resetValue();
      this.$refs.outstaffingRf.resetValue();
    },
  },
};
</script>

<style></style>
