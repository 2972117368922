<template>
  <div class="table-wrap" style="overflow: scroll;" v-if="isShow">
    <table-general-info-vue
      :name="name"
      :project="project"
      :fieldOfActivity="fieldOfActivity"
      ref="tableGeneralInfo"
    />
    <tables-outsource-vue
      :tablesList="tablesList"
      :outsource="outsource"
      :fss="fss"
      ref="tablesOutsource"
    />
    <div class="tableSmallWrap tableSmallWrap-outsors " style="margin-bottom:50px;">
      <table-small-outsource-vue
        v-for="table in tablesList"
        :key="table"
        :tablesList="table"
        :outsource="outsource"
        :fss="fss"
        ref="tablesSmall"
        class="tableSmallOutsource"
      />
    </div>
    <div class="container">
      <!-- <button
        @click="exportToExcel"
        type="button"
        class="export_btn outsource-bg"
      >
        Export to Excel
      </button> -->
    </div>
  </div>
</template>

<script>
import TableGeneralInfoVue from "../TableGeneralInfo.vue";
import TablesOutsourceVue from "./TablesOutsource.vue";
import TableSmallOutsourceVue from "./TableSmallOutsource.vue";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default {
  props: {
    isShow: Number,
    name: String,
    project: String,
    fieldOfActivity: String,
    outsource: Number,
    fss: Number,
    tablesList: Array,
  },
  components: {
    TablesOutsourceVue,
    TableGeneralInfoVue,
    TableSmallOutsourceVue,
  },

  data() {
    return {
      innerTable: [],
      range: "",
    };
  },
  methods: {
    exportToExcel() {
      const tableGeneral = this.$refs.tableGeneralInfo.$el;
      this.innerTable.push(tableGeneral);
      this.$refs.tablesSmall.forEach((table) => {
        const tableInner = table.$el;
        this.innerTable.push(tableInner);
      });

      let tables = [];
      this.innerTable.forEach((table) => {
        const sheet = XLSX.utils.table_to_sheet(table);
        const bob = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        tables.push(bob);
        this.innerTable = [];
      });

      let result;
      console.log(tables.length);
      for (let i = 0; i < tables.length; i++) {
        if (tables.length <= 1) {
          tables[0] = tables[0].concat([""]);
        } else if (tables[i + 1]) {
          tables[0] = tables[0].concat([""]).concat(tables[i + 1]);
        } else if (tables[tables.length - 1]) {
          tables;
        }
        result = tables[0];
      }
      console.log("ss");
      tables = [];

      var workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(result, { skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheetos");
      this.range = worksheet["!ref"];

      this.addStyle(workbook, result).then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", "outsource.xlsx");
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
      // XLSX.writeFile(workbook, "Report.xlsb", wopt);
    },

    addStyle(workbook, dataInfo) {
      return XlsxPopulate.fromBlankAsync(workbook).then((workbook) => {
        const sheet = workbook.sheet("Sheet1");
        const r = workbook.sheet(0).range(this.range);
        r.style({ horizontalAlignment: "center" });
        r.value(dataInfo);
        sheet.column("A").width(35);
        sheet.column("B").width(25);
        sheet.column("C").width(25);
        sheet.column("D").width(25);

        return workbook
          .outputAsync()
          .then((workbook) => URL.createObjectURL(workbook));
      });
    },
  },
};
</script>

<style>
.tableSmallWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .tableSmallWrap {
    justify-content: center;
  }

  .tableSmallWrap-outsors{
    justify-content: left !important;
  }
}
</style>
