<template>
  <label class="input_calc">
    <Transition name="slide-fade">
      <span
        class="error error-mrot"
        v-if="this.patent <= 25499 && !isDisableFotPatent"
        >Внимание, вы указали ЗП меньше МРОТ. <br /><br />Минимальная заработная плата по Санкт-Петербургу 25 500 рублей.</span
      >
    </Transition>
    <span>Патент</span>
    <input
      :disabled="isDisableFotPatent"
      type="number"
      min="15279"
      v-model="patent"
      @input="updateValue"
      required
      placeholder="0"
      class="wage-level__patent"
      id="inpFot__part"
    />

  </label>
</template>

<script>
export default {
  props: {
    isDisableFotPatent: {
      type: Boolean,
    },
  },

  data() {
    return {
      patent: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateFotPatent", this.patent);
    },
    resetValue() {
      this.patent = 0;
      this.$emit("updateFotPatent", this.patent);
    },
  },
};
</script>

<style></style>
