<template>
  <label class="input_calc">
    <span>Патент</span>
    <input
      type="number"
      min="0"
      v-model="countPatent"
      @input="updateValue"
      required
      placeholder="0"
      pattern="[0-9]"
      class="sum-staff__patent"
    />
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      countPatent: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updatePatent", this.countPatent);
    },
    initialValueOutsource() {
      this.countPatent = 0;
      this.$emit("updatePatent", this.countPatent);
    },
    resetValueOutsource() {
      this.countPatent = 0;
      this.$emit("updatePatent", this.countPatent);
    },
    resetValue() {
      this.countPatent = 0;
      this.$emit("updatePatent", this.countPatent);
    },
  },
};
</script>

<style></style>
