<template>
  <label class="input_calc">
    <!-- <input
      type="number"
      min="0"
      v-model="countPatent"
      @input="updateValue"
      :disabled="isDisableFotPatent"
      required
      placeholder="0"
      class="sum-staff__patent"
    /> -->
    <span>Патент</span>
      <select required v-model="countPatent" @change="updateValue" :disabled="isDisableFotPatent">
        <option disabled value="">График работы</option>
        <option value=22>5/2</option>
        <option value=26>6/1</option>
        <option value=15>2/2</option>
      </select>
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  props: {
    isDisableFotPatent: {
      type: Boolean,
    },
  },

  data() {
    return {
      countPatent: 22,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updatePatent", this.countPatent);
    },
    resetValue() {
      this.countPatent = 22;
      this.$emit("updatePatent", this.countPatent);
    },
  },
};
</script>

<style>
select{
  font-size: 21px;
  width: 108px;
  height: 50px;
}
</style>
