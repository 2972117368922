<template>
  <div class="left_block">
    <div class="left_block__inner">
      <div class="checkbox_wrapper row">
        <label class="input_calc">
          <span>Наименование юр. лица</span>
          <input
            type="text"
            class="input-name"
            @input="upGeneralInfo"
            v-model="generalInfo.companyName"
          />
        </label>
        <label class="input_calc">
          <span>Наименование проекта</span>
          <input
            type="text"
            class="input-project"
            @input="upGeneralInfo"
            v-model="generalInfo.projectName"
          />
        </label>
        <label class="input_calc">
          <span>Сфера деятельности</span>
          <input
            type="text"
            class="input-scope"
            @input="upGeneralInfo"
            v-model="generalInfo.scopeName"
          />
        </label>
        <label class="input_calc">
          <span>Регион</span>
          <input
            type="text"
            class="input-scope"
            @input="upGeneralInfo"
            v-model="generalInfo.regionName"
          />
        </label>
      </div>
      <span class="important_info"
        >"Укажите наименование юридического лица полностью : ООО "__", ЗАО "__",
        АО "__", ИП, НКО, ПАО и т.д."</span
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      generalInfo: {
        companyName: "",
        projectName: "",
        regionName: "",
      },
    };
  },

  methods: {
    upGeneralInfo() {
      this.$emit("upInfo", this.generalInfo);
    },
    resetGeneralInfo() {
      this.generalInfo = {
        companyName: "",
        projectName: "",
        scopeName: "",
        regionName: "",
      };
      this.$emit("upInfo", this.generalInfo);
    },
  },
};
</script>

<style></style>
