<template>
  <div id="passw" class="home-btns">
    <div style="margin-bottom: 50px; text-align: center">
      <p style="margin-bottom: 20px">
        Обновите страницу для получение последних обновлений калькулятора
      </p>
      <p style="margin-bottom: 20px">WINDOWS: ALT + SHIFT + R</p>
      <p style="margin-bottom: 20px">MAC OS: CMD + SHIFT + R</p>
    </div>
    <div>
      <input
        type="password"
        id="password"
        onkeydown="if (event.keyCode == 13) document.getElementById('button').click()"
      />
    </div>
    <div>
      <br />
      <input
        id="button"
        type="button"
        value="Login"
        onclick="if (document.getElementById('password').value == '123') {
document.getElementById('table').classList.toggle('show');   document.getElementById('passw').style.display='none'; }
else {  alert('Invalid Password!'); password.setSelectionRange(0, password.value.length);   } "
      />
    </div>
    <button
      class="home-btn outstaff-bg"
      style="margin-top: 30px"
      @click="$router.push('/signaturegen')"
    >
      Генератор подписей
    </button>
  </div>

  <table id="table" class="home-btns">
    <tr>
      <td></td>
      <td id="HIDDENDIV">
        <div class="home-btns home-wrapper">
          <button
            style="display: none"
            class="home-btn outstaff-bg"
            @click="$router.push('/outstaff')"
          >
            аутстаффинг
          </button>
          <button
            class="home-btn outstaff-bg"
            @click="$router.push('/outstaffEnp')"
          >
            Аутстаффинг ЕНП 2023
          </button>
          <button
            class="home-btn outstaff-bg"
            @click="$router.push('/outstaffEnpWout')"
          >
            Аутстаффинг ЕНП 2023<br />(Без льгот)
          </button>
          <button
            class="home-btn outsource-bg"
            @click="$router.push('/outsource')"
          >
            аутсорсинг ЕНП<br />(Без льгот)
          </button>

          <a
            class="home-btn outsource-bg"
            href="https://dpcost.ru/calc-sz.html"
          >
            Шаблон расчета
          </a>
          <a class="home-btn outsource-bg" href="https://dpcost.ru/calc-2.html">
            Бугалтерский учёт
          </a>

          <a class="home-btn outsource-bg" href="https://dpcost.ru/calc-1.html">
            кадровые услуги
          </a>
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "HomeVue",
};
</script>

<style scoped>
html,
body,
#app {
  height: 100%;
}

.home-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 20%;
}

.home-btn {
  padding: 30px 15px;
  font-size: 16px;
  text-align: center;
  width: 320px;
  color: white;
  margin: 0 auto 30px;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
}

.home-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0;
  column-gap: 25px;
}

.home-btn.outstaff-bg:hover {
  background: #0e70e0be;
  transition: 0.3s;
}
.home-btn.outstaff-bg:active {
  background: #0e6fe0;
  transition: 0.3s;
}

.home-btn.outsource-bg:hover {
  background: #07bbf2b2;
  transition: 0.3s;
}
.home-btn.outsource-bg:active {
  background: #07baf2;
  transition: 0.3s;
}

#HIDDENDIV {
  display: none;
}

#table.show tr > *:nth-child(2) {
  display: block;
}

.input-contain {
  position: relative;
}
input {
  height: 3rem;
  width: 20rem;
  border: 2px solid black;
  border-radius: 0.5rem;
}

.placeholder-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid transparent;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.placeholder-text {
  pointer-events: none;
}

.text {
  font-size: 1.4rem;
  padding: 0 0.5rem;
  background-color: transparent;
  color: black;
}

input,
.placeholder-text {
  font-size: 1.4rem;
  padding: 0 1.2rem;
}

input:focus {
  outline: none;
  border-color: #0e6fe0;
}

input:focus + .placeholder-text .text {
  background-color: white;
  font-size: 1.1rem;
  color: black;
  transform: translate(0, -170%);
  border-color: blueviolet;
  color: blueviolet;
}

.text {
  transform: translate(0);
  transition: transform 0.15s ease-out, font-size 0.15s ease-out,
    background-color 0.2s ease-out, color 0.15s ease-out;
}

input:focus + .placeholder-text .text,
:not(input[value=""]) + .placeholder-text .text {
  background-color: white;
  font-size: 1.1rem;
  color: black;
  transform: translate(0, -170%);
}

input:focus + .placeholder-text .text {
  border-color: #0e6fe0;
  color: #0e6fe0;
}

@media screen and (max-width: 768px) {
  .home-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 0;
    row-gap: 25px;
  }
}
</style>
