<template>
  <div id="app" class="accounting_calculator outstaffing_calculator">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="./style.css"></style>
