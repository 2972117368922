<template>
  <header-vue :heading="this.calcName" />
  <form-calc-outsource-vue @upProfessionList="updateData" />
  <table-wrap-outsource-vue
    :name="this.name"
    :project="this.project"
    :fieldOfActivity="this.fieldOfActivity"
    :outsource="this.outsource"
    :fss="this.fss"
    :isShow="this.isShow"
    :tablesList="this.tablesList"
    ref="tableWrapOutsourse"
  />
  <popup-warn-vue v-if="this.warnNonCalc" />
</template>

<script>
import HeaderVue from "../Header.vue";
import FormCalcOutsourceVue from "./FormCalcOutsource.vue";
import TableWrapOutsourceVue from "../Tables/OutsourceTables/TableWrapOutsource.vue";

import PopupWarnVue from "../PopupWarn.vue";

export default {
  components: {
    HeaderVue,
    FormCalcOutsourceVue,
    TableWrapOutsourceVue,
    PopupWarnVue,
  },
  data() {
    return {
      calcName: "Калькулятор аутсорсинг ЕНП (Без льгот)",
      name: "Не указано",
      project: "Не указано",
      fieldOfActivity: "Не указано",
      outsource: 0,
      fss: 0.2,
      tablesList: [],
      isShow: 0,
      warnNonCalc: false,
    };
  },
  methods: {
    updateData(data) {
      this.name = data.name;
      this.project = data.project;
      this.fieldOfActivity = data.fieldOfActivity;
      this.outsource = data.outsource;
      this.fss = data.fss;
      this.tablesList = data.tablesList;
      this.isShow = data.isShow;
    },
  },
};
</script>

<style></style>
