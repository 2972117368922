<template>
  <header-vue :heading="this.calcName" />

  <div class="container">
    <div class="form-signature" style="">
        <div class="form-signature__box">
            <div class="form-signature__field">
                <label for="fullname">Имя сотрудника</label>
                <input type="text" name="fullname" v-model="fullName">
            </div>

            <div class="form-signature__field">
                <label for="job-title">Должность</label>
                <input name="job-title" v-model="jobTitle" type="text">
            </div>
        </div>

        <div class="form-signature__box">
            <div class="form-signature__field">
                <label for="phone-number">Доп.номер</label>
                <input type="number" v-model="phoneNumber" name="phone-number" id="">
            </div>

            <div class="form-signature__field">
                <label for="whatsapp">WhatsApp текст<br><span>(Вводить с пробелами и +)</span></label>
                <input name="whatsapp" v-model="whatsapp2" type="tel">
            </div>

            <div class="form-signature__field">
                <label for="whatsapp">WhatsApp ссылка<br><span>(Вводить без пробелов и +)</span></label>
                <input name="whatsapp" v-model="whatsapp" type="tel">
            </div>
        </div>

        <div class="form-signature__box">
            <div class="form-signature__field">
                <label for="person-mail">Почтовый адрес 1</label>
                <input name="person-mail" v-model="personMail" type="text">
            </div>

            <div class="form-signature__field">
                <label for="department-mail">Почтовый адрес 2</label>
                <input name="department-mail" v-model="departmentMail" type="text">
            </div>
        </div>

    </div>

    <iframe :srcdoc="genSignature()" id="signature" width="500px" allow="clipboard-read; clipboard-write" frameborder="0">

    </iframe>
  </div>

</template>
<style>

    .form-signature{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 40px;
    }

    .form-signature__field{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 30px;
        gap: 15px;
        font-size: 18px;
    }

    .form-signature__field input{
        font-size: 18px;
        padding: 5px 10px ;
    }

    .form-signature__field label{
        text-align: center;
    }

    .form-signature__field label span{
        font-size: 14px;
        margin-top: 10px;
    }

    #signature{
        margin: 0 auto;
        display: flex;
        border: 1px solid black;
    }
</style>

<script>
  import HeaderVue from "../Header.vue";

  export default {
    components: {
      HeaderVue,
    },


    data() {
      return {
        calcName: "Генератор подписей",
        fullName: "Иван Иванов",
        jobTitle: "Сотрудник",
        phoneNumber: "404",
        whatsapp: "71234567899",
        whatsapp2: "7 123 456 78 99",
        departmentMail: "info@dpspb.com",
        personMail: "ivanov@dpspb.com"
      };
    },

    methods: {
        genSignature(){
            return `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
    <title>Document</title>
</head>

<style>
    table{
        font-family: 'Inter', sans-serif;
    }
    .main-img{
        padding-right: 10px;
        margin-right: 10px;
    }

</style>
<body>
    <table cellpadding="0" cellspacing="0">
        <tbody>
            <tr>
                <td rowspan="5" >
                    <img class="main-img" style="border-right: 2px solid #656565; max-width: 100px;" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/employees/dp.png" alt="">
                </td>
                <td colspan="2" style="font-size: 16px;">
                    ${this.fullName}
                </td>
            </tr>
            <tr>
                <td colspan="2" style="font-size: 12px; color: #656565; padding-bottom: 5px;">
                    ${this.jobTitle}
                </td>

                <td style="font-size: 18px;">
                    <a href="https://www.dpspb.com/" target="_blank" rel="noopener noreferrer">
                        <img style="margin-left: 20px;" width="20px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/site.png" alt="">
                    </a>
                </td>
            </tr>
            <tr style="font-size: 12px; color: #656565;">
                <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td><img style="margin-right: 5px;" width="15px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/call.png" alt=""></td>
                                    <td><a href="tel:+78126661600" style="font-size: 10px; color: #656565; text-decoration: none; white-space: nowrap;">+7 812 666 16 00</a> <span style="font-size: 10px; color: #656565;">(доб.${this.phoneNumber})</span></td>
                                </tr>
                            </tbody>
                        </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td><img style="margin-right: 5px; margin-left: 10px;" width="15px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/whatsapp.png" alt=""></td>
                                <td> <a href="https://wa.me/+${this.whatsapp}" target="_blank" style="font-size: 10px; color: #656565; text-decoration: none; white-space: nowrap;">+${this.whatsapp2}<span style="display: none;"> (Whatsapp / Viber)</span></a></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <a href="https://t.me/delpartinfo" target="_blank" rel="noopener noreferrer">
                        <img style="margin-left: 20px;" width="20px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/telegram.png" alt="">
                    </a>
                </td>
            </tr>
            <tr style="font-size: 12px; color: #656565;">
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td><img style="margin-right: 5px;" width="15px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/mail.png" alt=""></td>
                                <td><a href="mailto:${this.personMail}" style="font-size: 10px; color: #656565; text-decoration: none;">${this.personMail}
                                </a></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <table>
                        <tbody>
                            <tr>
                                <td><img style="margin-right: 5px;margin-left: 10px;" width="15px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/email.png" alt=""></td>
                                <td><a href="mailto:${this.departmentMail}" style="font-size: 10px; color: #656565; text-decoration: none;">${this.departmentMail}</a></td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td>
                    <a href="https://vk.com/del.part" target="_blank" rel="noopener noreferrer">
                        <img style="margin-left: 20px;" width="20px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/vk.png" alt="">
                    </a>
                </td>
            </tr>
            <tr style="font-size: 9px; color: #656565;">
                <td colspan="2" style="padding-top: 5px;">г.Санкт-Петербург, ул.Варшавская д.23к3, м.Парк Победы</td>
                <td>
                    <a href="https://www.instagram.com/del.part/" target="_blank" rel="noopener noreferrer">
                        <img style="margin-left: 20px;" width="20px" src="https://raw.githubusercontent.com/y0kem/y0kem.github.io/main/img/inst.png" alt="">
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</body>
</html>`;
        },
        copyToCustomCode(){

        }

    }
  }

</script>