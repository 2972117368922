import { createRouter, createWebHistory } from "vue-router";

import Home from "./components/Home.vue";
import OutstaffCalc from "./components/OutstaffCalc/OutstaffCalc.vue";
import OutstaffEnpCalc from "./components/OutstaffEnpCalc/OutstaffCalc.vue";
import OutstaffEnpWoutCalc from "./components/OutstaffEnpWoutCalc/OutstaffCalc.vue";
import OutsourceCalc from "./components/OutsourceCalc/OutsourceCalc.vue";

import BuxhCalc from "./components/BuxhCalc/BuxhCalc.vue";
import SignatureGen from "./components/Signature/SignatureGen.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/outstaff", component: OutstaffCalc },
  { path: "/outstaffEnp", component: OutstaffEnpCalc },
  { path: "/outstaffEnpWout", component: OutstaffEnpWoutCalc },
  { path: "/outsource", component: OutsourceCalc },
  { path: "/accountant", component: BuxhCalc },
  { path: "/signaturegen", component: SignatureGen },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
