<template>
  <header-vue :heading="this.calcName" />
  <form-calc-vue @updateParent="updateData" ref="formCalc" @upPopInfo="capa" />
  <table-wrap-outstaff-vue
    :name="this.name"
    :project="this.project"
    :fieldOfActivity="this.fieldOfActivity"
    :isShow="this.isShow"
    :tablesList="this.tablesList"
    ref="tableWrappo"
  />
  <Transition name="slide-fade">
    <popup-warn-vue v-if="this.warnNonCalc" v-model="this.tablesList" />
  </Transition>
</template>

<script>
import HeaderVue from "../Header.vue";
import FormCalcVue from "./FormCalcOutstaffing.vue";
import TableWrapOutstaffVue from "../Tables/OutstaffTables/TableWrapOutstaff.vue";
import PopupWarnVue from "../PopupWarn.vue";

export default {
  components: {
    HeaderVue,
    FormCalcVue,
    TableWrapOutstaffVue,
    PopupWarnVue,
  },
  data() {
    return {
      calcName: "Калькулятор аутстаффинг",
      name: "Не указано",
      project: "Не указано",
      fieldOfActivity: "Не указано",
      tablesList: [],
      isShow: 0,
      warnNonCalc: false,
    };
  },
  methods: {
    updateData(data) {
      this.name = data.name;
      this.project = data.project;
      this.fieldOfActivity = data.fieldOfActivity;
      this.tablesList = data.tablesList;
      this.isShow = data.isShow;

      // this.$refs.tableWrappo.$refs.tableSmall.checkObj();
      // this.$refs.tableWrappo.$refs.tableSmall.calc();
    },
    calculate() {
      this.$refs.formCalc.genPDF();
    },
    capa(warnNonCalc) {
      this.warnNonCalc = warnNonCalc;
    },
  },
};
</script>

<style></style>
