<template>
  <table-vue
    v-for="(table, indx) of tablesList"
    :key="table"
    :tablesList="tablesList[indx]"
    ref="tableOutsraff"
  />
</template>

<script>
import TableVue from "./Table.vue";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default {
  name: "TablesVue",
  props: ["tablesList"],
  components: {
    TableVue,
  },

  data() {
    return {
      innerTable: [],
      range: "",
    };
  },

  methods: {
    exportToExcel() {
      this.$refs.tableOutsraff;

      this.$refs.tableOutsraff.forEach((table) => {
        const tableInner = table.$el;
        this.innerTable.push(tableInner);
      });

      let tables = [];
      this.innerTable.forEach((table) => {
        const sheet = XLSX.utils.table_to_sheet(table);
        const bob = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        tables.push(bob);
        this.innerTable = [];
      });

      for (let i = 0; i < tables.length; i++) {
        if (tables.length <= 1) {
          tables[0] = tables[0].concat([""]);
        }
        if (tables[i + 1]) {
          tables[0] = tables[0].concat([""]).concat(tables[i]);
        }
      }
      let result = tables[0];
      tables = [];

      var workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(result, { skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheetos");
      this.range = worksheet["!ref"];

      this.addStyle(workbook, result).then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", "outstaff.xlsx");
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
      // XLSX.writeFile(workbook, "Report.xlsb", wopt);
    },

    addStyle(workbook, dataInfo) {
      return XlsxPopulate.fromBlankAsync(workbook).then((workbook) => {
        const sheet = workbook.sheet("Sheet1");
        const r = workbook.sheet(0).range(this.range);
        // const rTitle = workbook.sheet(0).range();
        r.style({ horizontalAlignment: "center" });
        r.value(dataInfo);
        sheet.column("A").width(20);
        sheet.column("B").width(15);
        sheet.column("C").width(15);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);
        sheet.column("I").width(15);
        sheet.column("J").width(15);
        sheet.column("K").width(17);
        sheet.column("L").width(22);
        sheet.column("M").width(22);
        sheet.column("N").width(22);
        sheet.column("O").width(22);
        sheet.column("P").width(15);
        sheet.column("Q").width(15);

        return workbook
          .outputAsync()
          .then((workbook) => URL.createObjectURL(workbook));
      });
    },
  },
};
</script>

<style></style>
