<template>
  <div class="right">
    <div class="container container-submitReset">

      <button
        class="calc_but"
        @click.prevent="$emit('calc')"
        style="width: 100%"
        :class="{ 'outstaff-bg': isOustaff, 'outsource-bg': isOutsource }"
      >
        Рассчитать
      </button>
      <button
        @click.prevent="$emit('reset')"
        class="resetFormBtn"
        :class="{ 'outstaff-bg': isOustaff, 'outsource-bg': isOutsource }"
      >
        Очистить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubmitResetVue",
  props: {
    type: String,
  },

  data() {
    return {
      isOustaff: null,
      isOutsource: null,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (this.type == "outsource") {
        this.isOutsource = true;
        this.isOustaff = false;
      } else if (this.type == "outstaff") {
        this.isOutsource = false;
        this.isOustaff = true;
      }
    });
  },
};
</script>

<style>
.container-submitReset {
  display: flex;
  gap: 15px;
}
.resetFormBtn {
  font-size: 19px;
  display: block;
  padding: 18px 20px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none;
  margin: 40px 0;
}
.accounting_calculator .calc_but {
  font-size: 19px;
  display: block;
  padding: 18px 20px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
}

#checkbox{
    font-size: 21px;
    color: #000;
    line-height: 100%;
}
#checkbox-label{
    font-size: 21px;
    color: #000;
    line-height: 100%;
}
</style>
