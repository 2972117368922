<template>
  <label class="input_calc">
    <span>EAЭC</span>
    <input
      type="number"
      min="0"
      v-model="countEaeu"
      @input="updateValue"
      :disabled="isDisableFotEaeu"
      required
      placeholder="0"
      class="sum-staff__eaeu"
    />
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  props: {
    isDisableFotEaeu: {
      type: Boolean,
    },
  },
  data() {
    return {
      countEaeu: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateEaeu", this.countEaeu);
    },
    resetValue() {
      this.countEaeu = null;
      this.$emit("updateEaeu", this.countEaeu);
    },
  },
};
</script>

<style></style>
