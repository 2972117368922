<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>Система налогообложения Заказчика</h3>
      <div class="checkbox_wrapper flex_column">
        <label class="checkbox_calc">
          <input
            type="radio"
            name="radio1"
            id="ycn"
            value="ycn"
            class="usn-percent"
            v-model="selectedSystemTax"
            @change="updateValue"
          />
          <span class="checkbox">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 32 32"
              style="enable-background: new 0 0 32 32"
              xml:space="preserve"
            >
              <g>
                <g id="check">
                  <g>
                    <polygon
                      style="fill: #fff"
                      points="11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 32,8.818 			"
                    />
                  </g>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </span>
          <span class="name">УСН</span>
        </label>
        <label class="checkbox_calc">
          <input
            type="radio"
            name="radio1"
            id="nds20"
            value="nds20"
            class="nds-percent"
            v-model="selectedSystemTax"
            @change="updateValue"
          />
          <span class="checkbox">
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 32 32"
              style="enable-background: new 0 0 32 32"
              xml:space="preserve"
            >
              <g>
                <g id="check">
                  <g>
                    <polygon
                      style="fill: #fff"
                      points="11.941,28.877 0,16.935 5.695,11.24 11.941,17.486 26.305,3.123 32,8.818 			"
                    />
                  </g>
                </g>
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </span>
          <span class="name">НДС 20 %</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedSystemTax: "ycn",
    };
  },

  methods: {
    updateValue() {
      this.$emit("upSystemTax", this.selectedSystemTax);
    },
  },
};
</script>

<style></style>
