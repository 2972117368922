<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>ФСС травма</h3>
      <div class="checkbox_wrapper fss">
        <label class="input_calc">
          <input
            type="number"
            min="0"
            step="0.1"
            v-model="fss"
            @input="updateValue"
            required
            placeholder="0"
            class="fcc-injury"
            id="fssInput"
          />
          <span>Среднее значение 0,2%</span>
          <span class="error"></span>
        </label>
      </div>
      <div class="important_info">
        Уточнить класс опасности у заказчика либо основной код ОКВЭД
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fss: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("upFss", this.fss);
    },
    resetValue() {
      this.fss = 0.2;
      this.$emit("upFss", this.fss);
    },
  },
};
</script>

<style></style>
