<template>
  <div class="table-wrap" 
  v-if="isShow"
  style="display: grid;grid-template-columns: 1fr 1fr 1fr;gap: 50px; margin-bottom: 50px; overflow-x: scroll;">
    <table-general-info-vue
      :name="name"
      :project="project"
      :fieldOfActivity="fieldOfActivity"
      ref="tableGeneralInfo"
    />
    <tables-vue :tablesList="tablesList" ref="tablesOutstaff" />
    <!-- <table-small-vue :tablesList="tablesList" ref="tableSmall" />
    <button @click="exportToExcel" type="button" class="export_btn outstaff-bg">
      Export to Excel
    </button> -->
  </div>
</template>

<script>
import TablesVue from "./Tables.vue";
// import TableSmallVue from "./TableSmall.vue";
import TableGeneralInfoVue from "../TableGeneralInfo.vue";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export default {
  props: {
    isShow: Number,
    name: String,
    project: String,
    fieldOfActivity: String,
    tablesList: Array,
  },
  components: {
    TablesVue,
    // TableSmallVue,
    TableGeneralInfoVue,
  },

  data() {
    return {
      innerTable: [],
      range: "",
    };
  },

  methods: {
    exportUP() {
      this.$refs.tablesOutstaff.exportToExcel();
      console.log("check")
    },
    exportToExcel() {
      const tableGeneral = this.$refs.tableGeneralInfo.$el;
      this.innerTable.push(tableGeneral);

      this.$refs.tablesOutstaff.$refs.tableOutsraff.forEach((table) => {
        const tableInner = table.$el;
        this.innerTable.push(tableInner);
      });
      const tableInnerSmall = this.$refs.tableSmall.$el;
      this.innerTable.push(tableInnerSmall);

      let tables = [];
      this.innerTable.forEach((table) => {
        const sheet = XLSX.utils.table_to_sheet(table);
        const bob = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        tables.push(bob);
        this.innerTable = [];
      });

      let result;
      if (tables.length == 2) {
        result = tables[0].concat([""]).concat(tables[1]);
      }
      if (tables.length == 3) {
        result = tables[0]
          .concat([""])
          .concat(tables[1])
          .concat([""])
          .concat(tables[2]);
      }
      if (tables.length == 4) {
        result = tables[0]
          .concat([""])
          .concat(tables[1])
          .concat([""])
          .concat(tables[2])
          .concat([""])
          .concat(tables[3]);
      }
      if (tables.length == 5) {
        result = tables[0]
          .concat([""])
          .concat(tables[1])
          .concat([""])
          .concat(tables[2])
          .concat([""])
          .concat(tables[3])
          .concat([""])
          .concat(tables[4]);
      }
      tables = [];

      var workbook = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet(result, { skipHeader: true });
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheetos");
      this.range = worksheet["!ref"];

      this.addStyle(workbook, result).then((url) => {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute("download", "outstaff.xlsx");
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
      // XLSX.writeFile(workbook, "Report.xlsb", wopt);
    },

    addStyle(workbook, dataInfo) {
      return XlsxPopulate.fromBlankAsync(workbook).then((workbook) => {
        const sheet = workbook.sheet("Sheet1");
        const r = workbook.sheet(0).range(this.range);
        // const rTitle = workbook.sheet(0).range();
        r.style({ horizontalAlignment: "center" });
        r.value(dataInfo);
        sheet.column("A").width(25);
        sheet.column("B").width(25);
        sheet.column("C").width(25);
        sheet.column("D").width(15);
        sheet.column("E").width(15);
        sheet.column("F").width(15);
        sheet.column("G").width(15);
        sheet.column("H").width(15);
        sheet.column("I").width(15);
        sheet.column("J").width(15);
        sheet.column("K").width(17);
        sheet.column("L").width(22);
        sheet.column("M").width(22);
        sheet.column("N").width(22);
        sheet.column("O").width(22);
        sheet.column("P").width(15);
        sheet.column("Q").width(15);

        return workbook
          .outputAsync()
          .then((workbook) => URL.createObjectURL(workbook));
      });
    },
  },
};
</script>

<style>
    

.table-wrap table:first-child{
  grid-column-start: 1;
  grid-column-end: 4;
}
.table-wrap table:nth-child(2){
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
}

.table-wrap table:nth-child(3){
    grid-column-start: 1;
    grid-column-end: 4;
    max-width: 320px;
    
}


.table-wrap table:nth-child(4){
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
}

.table-wrap table:nth-child(5){
    grid-column-start: 1;
    grid-column-end: 4;
    max-width: 320px;
    
}


.table-wrap table:nth-child(6){
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 4;
}

.table-wrap table:nth-child(7){
    grid-column-start: 1;
    grid-column-end: 4;
    max-width: 320px;
    
}

@media screen and (max-width: 768px) {
  .table-wrap table:first-child{
    grid-column-start: 1;
    grid-column-end: 1;
  }
}

</style>
