<template>
  <table class="tg tabletka">
    <thead>
      <tr>
        <th class="tg-bobw" colspan="4">
          <span style="font-weight: bold"
            >ИТОГОВЫЙ РАСЧЕТ: - <slot>{{ tablesList.profession }}</slot></span
          >
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-a08a">
          <span style="background-color: #f8cbad">Статус сотрудника </span>
        </td>
        <td class="tg-s4mc" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal; background-color: #f8cbad"
            >Патент</span
          >
        </td>
        <td class="tg-s4mc" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal; background-color: #f8cbad"
            >ЕАЭС
          </span>
        </td>
        <td class="tg-s4mc" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal; background-color: #f8cbad">
            РФ</span
          >
        </td>
      </tr>

      <tr style="visibility: hidden;">
        qwe
      </tr>
<!-- qwe -->

      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Заработная плата сотрудника</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.patent - this.ndfl.patent ) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.eaeu - this.ndfl.eaeu) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.rf - this.ndfl.rf) }}</slot> ₽</span
          >
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Стоимость часа сотрудника
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">

          <span style="font-weight: normal" v-if="tableListPatent.days == 22"
            ><slot>{{
              formated(this.fotFull.patent/(8*tableListPatent.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26"
            ><slot>{{
              formated(this.fotFull.patent/(12*tableListPatent.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(this.fotFull.patent/(12*tableListPatent.days))
            }}</slot> ₽
          </span>

        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">

          <span style="font-weight: normal" v-if="tableListEaeu.days == 22"
            ><slot>{{
              formated(this.fotFull.eaeu/(8*tableListEaeu.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26"
            ><slot>{{
              formated(this.fotFull.eaeu/(12*tableListEaeu.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(this.fotFull.eaeu/(12*tableListEaeu.days))
            }}</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">

          <span style="font-weight: normal" v-if="tableListRf.days == 22"
            ><slot>{{
              formated(this.fotFull.rf/(8*tableListRf.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26"
            ><slot>{{
              formated(this.fotFull.rf/(12*tableListRf.days))
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(this.fotFull.rf/(12*tableListRf.days))
            }}</slot> ₽
          </span>
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >График работы
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">


          <span style="font-weight: normal" v-if="tableListPatent.days == 22"
            ><slot>5/2</slot>
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26"
            ><slot>6/1</slot>
          </span>
          <span style="font-weight: normal" v-else
            ><slot>2/2</slot>
          </span>

        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal" v-if="tableListEaeu.days == 22"
            ><slot>5/2</slot>
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26"
            ><slot>6/1</slot>
          </span>
          <span style="font-weight: normal" v-else
            ><slot>2/2</slot>
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal" v-if="tableListRf.days == 22"
            ><slot>5/2</slot>
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26"
            ><slot>6/1</slot>
          </span>
          <span style="font-weight: normal" v-else
            ><slot>2/2</slot>
          </span>
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Человека час ТД </span>
        </td>
        <!-- <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.emloyTd.patent) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.emloyTd.eaeu) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.emloyTd.rf) }}</slot> ₽</span
          >
        </td> -->

        <!-- <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal" v-if="tableListPatent.days == 22">
            <slot v-if="((this.fotFull.patent - this.ndfl.patent)/176) > 143">{{ formated((this.fotFull.patent - this.ndfl.patent)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26">
            <slot v-if="((this.fotFull.patent - this.ndfl.patent)/312) > 143">{{ formated((this.fotFull.patent - this.ndfl.patent)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.fotFull.patent - this.ndfl.patent)/180) > 143">{{ formated((this.fotFull.patent - this.ndfl.patent)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal" v-if="tableListEaeu.days == 22">
            <slot v-if="((this.fotFull.eaeu - this.ndfl.eaeu)/176) > 143">{{ formated((this.fotFull.eaeu - this.ndfl.eaeu)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26">
            <slot v-if="((this.fotFull.eaeu - this.ndfl.eaeu)/312) > 143">{{ formated((this.fotFull.eaeu - this.ndfl.eaeu)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.fotFull.eaeu - this.ndfl.eaeu)/180) > 143">{{ formated((this.fotFull.eaeu - this.ndfl.eaeu)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal" v-if="tableListRf.days == 22">
            <slot v-if="((this.fotFull.rf - this.ndfl.rf)/176) > 143">{{ formated((this.fotFull.rf - this.ndfl.rf)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26">
            <slot v-if="((this.fotFull.rf - this.ndfl.rf)/312) > 143">{{ formated((this.fotFull.rf - this.ndfl.rf)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.fotFull.rf - this.ndfl.rf)/180) > 143">{{ formated((this.fotFull.rf - this.ndfl.rf)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td> -->


        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal" v-if="tableListPatent.days == 22">
            <slot v-if="((this.reglamentTD.patent)/176) > 143">{{ formated((this.reglamentTD.patent)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26">
            <slot v-if="((this.reglamentTD.patent)/312) > 143">{{ formated((this.reglamentTD.patent)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.reglamentTD.patent)/180) > 143">{{ formated((this.reglamentTD.patent)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal" v-if="tableListEaeu.days == 22">
            <slot v-if="((this.reglamentTD.eaeu)/176) > 143">{{ formated((this.reglamentTD.eaeu)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26">
            <slot v-if="((this.reglamentTD.eaeu)/312) > 143">{{ formated((this.reglamentTD.eaeu)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.reglamentTD.eaeu)/180) > 143">{{ formated((this.reglamentTD.eaeu)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal" v-if="tableListRf.days == 22">
            <slot v-if="((this.reglamentTD.rf)/176) > 143">{{ formated((this.reglamentTD.rf)/176) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26">
            <slot v-if="((this.reglamentTD.rf)/312) > 143">{{ formated((this.reglamentTD.rf)/312) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
          <span style="font-weight: normal" v-else>
            <slot v-if="((this.reglamentTD.rf)/180) > 143">{{ formated((this.reglamentTD.rf)/180) }}</slot>
            <slot v-else>143</slot> ₽
          </span>
        </td>
      </tr>

      <!-- asd -->
      <tr style="visibility: hidden;">
        qwe
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Аутсорсинг </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.patent) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.eaeu) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.rf) }}</slot> ₽</span
          >
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Человека час для клиента (без НДС)
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <!-- <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.sumWithoutNdc.patent /
                  (tableListPatent.hours * tableListPatent.days)
              )
            }}</slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListPatent.days == 22"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.patent /
                  (8 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.patent /
                  (12 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                this.newSumWithoutNdc.patent /
                  (12 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <!-- <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.sumWithoutNdc.eaeu /
                  (tableListEaeu.hours * tableListEaeu.days)
              )
            }}</slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListEaeu.days == 22"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.eaeu /
                  (8 * tableListEaeu.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.eaeu /
                  (12 * tableListEaeu.days)
              )

            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                this.newSumWithoutNdc.eaeu /
                  (12 * tableListEaeu.days)
              )
            }}</slot> ₽
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <!-- <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.sumWithoutNdc.rf / (tableListRf.hours * tableListRf.days)
              )
            }}</slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListRf.days == 22"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.rf /
                  (8 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26"
            ><slot>{{
              formated(
                this.newSumWithoutNdc.rf /
                  (12 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                this.newSumWithoutNdc.rf /
                  (12 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Человека час для клиента (с НДС)
          </span>
        </td>
        <td
          class="tg-2b7s"
          :class="{ bgErr: this.emloyTdMin.patent }"
          v-if="Object.keys(tableListPatent).length"
        >
          <!-- <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.sumAndNdc.patent /
                  (tableListPatent.hours * tableListPatent.days)
              )
            }}</slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListPatent.days == 22"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.patent * 1.2 )/
                  (8 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListPatent.days == 26"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.patent * 1.2 ) /
                  (12 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.patent * 1.2 ) /
                  (12 * tableListPatent.days)
              )
            }}</slot> ₽
          </span>
        </td>
        <td
          class="tg-2b7s"
          :class="{ bgErr: this.emloyTdMin.eaeu }"
          v-if="Object.keys(tableListEaeu).length"
        >
          <!-- <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.sumAndNdc.eaeu / (tableListEaeu.hours * tableListEaeu.days)
              )
            }}</slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListEaeu.days == 22"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.eaeu * 1.2 ) /
                  (8 * tableListEaeu.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListEaeu.days == 26"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.eaeu * 1.2 ) /
                  (12 * tableListEaeu.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.eaeu * 1.2 ) /
                  (12 * tableListEaeu.days)
              )
            }}</slot> ₽
          </span>
        </td>
        <td
          class="tg-2b7s"
          :class="{ bgErr: this.emloyTdMin.rf }"
          v-if="Object.keys(tableListRf).length"
        >
          <!-- <span style="font-weight: normal">
            <slot
              >{{
                formated(
                  this.sumAndNdc.rf / (tableListRf.hours * tableListRf.days)
                )
              }}
            </slot>
            ₽</span
          > -->

          <span style="font-weight: normal" v-if="tableListRf.days == 22"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.rf * 1.2 ) /
                  (8 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else-if="tableListRf.days == 26"
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.rf * 1.2 )  /
                  (12 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
          <span style="font-weight: normal" v-else
            ><slot>{{
              formated(
                (this.newSumWithoutNdc.rf * 1.2 )  /
                  (12 * tableListRf.days)
              )
            }}</slot> ₽
          </span>
        </td>
      </tr>

      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Счет для клиента без НДС </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.patent) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.eaeu) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.rf) }}</slot> ₽</span
          >
        </td>
      </tr>

      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Счет для клиента с НДС </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.patent * 1.2) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.eaeu * 1.2) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.newSumWithoutNdc.rf * 1.2) }}</slot> ₽</span
          >
        </td>
      </tr>

      <!-- <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Тест Управленка </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fssTrauma.patent) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fssTrauma.eaeu) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fssTrauma.rf) }}</slot> ₽</span
          >
        </td>
      </tr> -->

      <!-- <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Тест ЕНП </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.fss.patent) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.fss.patent) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.fss.patent) }}</slot> ₽</span
          >
        </td>
      </tr> -->

      <tr style="visibility: hidden;">
        qwe
      </tr>
      <!-- <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Коэффициент без НДС</span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumWithoutNdc.patent / this.tableListPatent.fot)
            }}</slot>
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumWithoutNdc.eaeu / this.tableListEaeu.fot)
            }}</slot>
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumWithoutNdc.rf / this.tableListRf.fot)
            }}</slot>
          </span>
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Коэффициент с НДС </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumAndNdc.patent / this.tableListPatent.fot)
            }}</slot>
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumAndNdc.eaeu / this.tableListEaeu.fot)
            }}</slot>
          </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.sumAndNdc.rf / this.tableListRf.fot)
            }}</slot>
          </span>
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">ЗП на руки за вычетом НДФЛ </span>
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.patent - this.ndfl.patent) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.eaeu - this.ndfl.eaeu) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.fotFull.rf - this.ndfl.rf) }}</slot> ₽</span
          >
        </td>
      </tr> -->
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Вознаграждение менеджера с человека</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.patent * 0.1) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.eaeu * 0.1) }}</slot> ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.rf * 0.1) }}</slot> ₽</span
          >
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Вознаграждение менеджера с оборота</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListPatent).length">
          <span style="font-weight: normal"
            ><slot>{{
              formated(this.tablesList.outsource.patent * 0.1 * tableListPatent.countEmpol)
            }}</slot></span
          >
          ₽
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListEaeu).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.eaeu * 0.1 * tableListEaeu.countEmpol) }}</slot>
            ₽</span
          >
        </td>
        <td class="tg-2b7s" v-if="Object.keys(tableListRf).length">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outsource.rf * 0.1 * tableListRf.countEmpol) }}</slot>
            ₽</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Caclulate from "../../../services/calculateNew";

export default {
  props: ["tablesList", "outsource", "fss"],
  data() {
    return {
      CONSTS: {
        FOT_FIX: 19242,
        NDFL: 0.13,
        NDS: 0.2,
        PFR_FIX: 0.22,
        PFR_REMAINDER: 0.1,
        FSS_INSURANCE: 0.018,
        FSS_OTHER: 0.029,
        FFOMC_FIX: 0.051,
        FFOMC_REMAINDER: 0.05,
      },
      tableListPatent: {},
      tableListEaeu: {},
      tableListRf: {},
      reglament: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      reglamentTD: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      reglamentRemain: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      pfr: { patent: 0, eaeu: 0, rf: 0 },
      pfrRemained: { patent: 0, eaeu: 0, rf: 0 },
      ndfl: {},
      fotFull: {},
      newSumWithoutNdc:{
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      salary:{
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      ffomc: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      ffomcRemained: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      fssC: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      fssTrauma: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      tax: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      sumWithoutNdc: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      ndc: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      sumAndNdc: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      rewardManagerEmloy: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      rewardManagerSum: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      workHours: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
      emloyTd: {
        patent: 125,
        eaeu: 125,
        rf: 125,
      },
      emloyTdMin: {
        patent: false,
        eaeu: false,
        rf: false,
      },
      manager: {
        patent: 0,
        eaeu: 0,
        rf: 0,
      },
    };
  },

  methods: {
    getInnerHTML(table) {
      // const innerTable = table.innerHTML;
      return table;
    },

    formated(value) {
      let formatter = new Intl.NumberFormat("ru", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },

    calcEmloyTd() {
      // this.emloyTd.patent =
      //   this.reglament.patent /
      //   (this.tableListPatent.hours * this.tableListPatent.days);

      // this.emloyTd.eaeu =
      //   this.reglament.eaeu /
      //   (this.tableListEaeu.hours * this.tableListEaeu.days);

      // this.emloyTd.rf =
      //   this.reglament.rf / (this.tableListRf.hours * this.tableListRf.days);

        if (this.tableListPatent.days == 22) {
        this.emloyTd.patent =
        this.reglament.patent /
        (8 * 22);
        }else if (this.tableListPatent.days == 15) {
        this.emloyTd.patent =
        this.reglament.patent /
        (8 * 15);
        } else{
          this.emloyTd.patent = this.reglament.patent / (8 * 26);
        }


        if (this.tableListEaeu.days == 22) {
        this.emloyTd.eaeu =
        this.reglament.eaeu /
        (8 * 22);
        }else if (this.tableListEaeu.days == 15) {
        this.emloyTd.eaeu =
        this.reglament.eaeu /
        (8 * 15);
        } else{
          this.emloyTd.eaeu = this.reglament.eaeu / (8 * 26);
        }


        if (this.tableListRf.days == 22) {
        this.emloyTd.rf =
        this.reglament.rf /
        (8 * 22);
        }else if (this.tableListRf.days == 15) {
        this.emloyTd.rf =
        this.reglament.rf /
        (8 * 15);
        } else{
          this.emloyTd.rf = this.reglament.rf / (8 * 26);
        }

      if (Number(this.emloyTd.patent) < 143) {
        this.emloyTdMin.patent = true;
        this.emloyTd.patent = 143;
      } else if (Number(this.emloyTd.patent) > 143) {
        this.emloyTdMin.patent = false;
      }
      if (this.emloyTd.eaeu < 143) {
        this.emloyTdMin.eaeu = true;
        this.emloyTd.eaeu = 143;
      } else {
        this.emloyTdMin.eaeu = false;
      }
      if (this.emloyTd.rf < 143) {
        this.emloyTdMin.rf = true;
        this.emloyTd.rf = 143;
      } else {
        this.emloyTdMin.rf = false;
      }
    },
    checkObj() {
      this.tableListPatent = {};
      this.tableListEaeu = {};
      this.tableListRf = {};
      this.tablesList.proffestionEmploy.forEach((obj) => {
        if (obj.name == "ПАТЕНТ") {
          this.tableListPatent = obj;
        }
        if (obj.name == "ЕАЭС") {
          this.tableListEaeu = obj;
        }
        if (obj.name == "РФ") {
          this.tableListRf = obj;
        }
      });
    },
    calcReglament() {
      this.reglament.patent = Caclulate.reglament(this.tableListPatent.fot);
      this.reglament.eaeu = Caclulate.reglament(this.tableListEaeu.fot);
      this.reglament.rf = Caclulate.reglament(this.tableListRf.fot);
    },

    calcReglamentTd() {
      this.reglamentTD.patent = Caclulate.reglamentTD(this.tableListPatent.fot);
      this.reglamentTD.eaeu = Caclulate.reglamentTD(this.tableListEaeu.fot);
      this.reglamentTD.rf = Caclulate.reglamentTD(this.tableListRf.fot);
    },
    calcReglamentRemain() {
      if (this.reglament.patent <= this.CONSTS.FOT_FIX) {
        this.reglamentRemain.patent = Caclulate.reglamentRemain(
        this.reglament.patent,
        1
      );
      }else{
        this.reglamentRemain.patent = Caclulate.reglamentRemain(
        this.reglament.patent,
        this.CONSTS.FOT_FIX
      );
      }

      this.reglamentRemain.eaeu = Caclulate.reglamentRemain(
        this.reglament.eaeu,
        this.CONSTS.FOT_FIX
      );
      this.reglamentRemain.rf = Caclulate.reglamentRemain(
        this.reglament.rf,
        this.CONSTS.FOT_FIX
      );
    },
    calcNdfl() {
      this.ndfl.patent = Caclulate.calcNdfl(
        this.tableListPatent.fot,
        this.CONSTS.NDFL
      );
      this.ndfl.eaeu = Caclulate.calcNdfl(
        this.tableListEaeu.fot,
        this.CONSTS.NDFL
      );
      this.ndfl.rf = Caclulate.calcNdfl(this.tableListRf.fot, this.CONSTS.NDFL);
    },
    calcFotFull() {
      this.fotFull.patent = Caclulate.calcFotFull(
        this.tableListPatent.fot,
        this.ndfl.patent
      );
      this.fotFull.eaeu = Caclulate.calcFotFull(
        this.tableListEaeu.fot,
        this.ndfl.eaeu
      );
      this.fotFull.rf = Caclulate.calcFotFull(
        this.tableListRf.fot,
        this.ndfl.rf
      );
    },
    calcFfomc() {
      this.ffomc.eaeu = Caclulate.calcFfomc(
        this.CONSTS.FOT_FIX,
        this.CONSTS.FFOMC_FIX
      );
      this.ffomc.rf = Caclulate.calcFfomc(
        this.CONSTS.FOT_FIX,
        this.CONSTS.FFOMC_FIX
      );

      this.ffomcRemained.eaeu = Caclulate.calcFfomc(
        this.reglamentRemain.eaeu,
        this.CONSTS.FFOMC_REMAINDER
      );
      this.ffomcRemained.rf = Caclulate.calcFfomc(
        this.reglamentRemain.rf,
        this.CONSTS.FFOMC_REMAINDER
      );
    },
    calcFss() {
      this.fssC.patent = Caclulate.calcFss(
        this.CONSTS.FOT_FIX,
        this.tableListPatent.name
      );
      this.fssC.eaeu = Caclulate.calcFss(
        this.CONSTS.FOT_FIX,
        this.tableListEaeu.name
      );
      this.fssC.rf = Caclulate.calcFss(
        this.CONSTS.FOT_FIX,
        this.tableListRf.name
      );
    },
    calcFssTrauma() {
      this.fssTrauma.patent = Caclulate.calcFssTrauma(
        this.tableListPatent.fot,
        this.tablesList.fss.patent
      );
      this.fssTrauma.eaeu = Caclulate.calcFssTrauma(
        this.tableListEaeu.fot,
        this.tablesList.fss.eaeu
      );
      this.fssTrauma.rf = Caclulate.calcFssTrauma(this.tableListRf.fot,
      this.tablesList.fss.rf);
    },
    calcPfr() {
      this.pfr.patent = Caclulate.calcPfr(
        this.CONSTS.FOT_FIX,
        this.CONSTS.PFR_FIX
      );
      this.pfr.eaeu = Caclulate.calcPfr(
        this.CONSTS.FOT_FIX,
        this.CONSTS.PFR_FIX
      );
      this.pfr.rf = Caclulate.calcPfr(this.CONSTS.FOT_FIX, this.CONSTS.PFR_FIX);

      this.pfrRemained.patent = Caclulate.calcPfr(
        this.reglamentRemain.patent,
        this.CONSTS.PFR_REMAINDER
      );
      this.pfrRemained.eaeu = Caclulate.calcPfr(
        this.reglamentRemain.eaeu,
        this.CONSTS.PFR_REMAINDER
      );
      this.pfrRemained.rf = Caclulate.calcPfr(
        this.reglamentRemain.rf,
        this.CONSTS.PFR_REMAINDER
      );
    },
    calcTax() {
      this.tax.patent = Caclulate.calcTax(
        this.pfr.patent,
        this.pfrRemained.patent,
        this.fssC.patent,
        this.fssTrauma.patent,
        this.ffomc.patent,
        this.ffomcRemained.patent,
        this.tableListPatent.name
      );
      this.tax.eaeu = Caclulate.calcTax(
        this.pfr.eaeu,
        this.pfrRemained.eaeu,
        this.fssC.eaeu,
        this.fssTrauma.eaeu,
        this.ffomc.eaeu,
        this.ffomcRemained.eaeu,
        this.tableListEaeu.name
      );
      this.tax.rf = Caclulate.calcTax(
        this.pfr.rf,
        this.pfrRemained.rf,
        this.fssC.rf,
        this.fssTrauma.rf,
        this.ffomc.rf,
        this.ffomcRemained.rf,
        this.tableListRf.name
      );
    },

    calcManager() {
      this.manager.patent = Caclulate.calcManager(
        this.tableListPatent.fot,
        this.ndfl.patent
      );
      this.manager.eaeu = Caclulate.calcManager(
        this.tableListEaeu.fot,
        this.ndfl.eaeu
      );
      this.manager.rf = Caclulate.calcManager(
        this.tableListRf.fot,
        this.ndfl.rf
      );
    },

    calcSumWithoutNdc() {
      this.sumWithoutNdc.patent = Caclulate.calcSumWithoutNdcCom(
        this.fotFull.patent,
        this.tax.patent,
        this.tablesList.outsource.patent,
        this.manager.patent * 0.22
      );
      this.sumWithoutNdc.eaeu = Caclulate.calcSumWithoutNdcCom(
        this.fotFull.eaeu,
        this.tax.eaeu,
        this.tablesList.outsource.eaeu,
        this.manager.eaeu * 0.22
      );
      this.sumWithoutNdc.rf = Caclulate.calcSumWithoutNdcCom(
        this.fotFull.rf,
        this.tax.rf,
        this.tablesList.outsource.rf,
        this.manager.rf * 0.22
      );
    },

    calcNewSumWithoutNdc() {
      this.newSumWithoutNdc.patent = Caclulate.calcNewSumWithoutNdc(
        this.tableListPatent.fot,
        this.fotFull.patent,
        this.ndfl.patent,
        this.reglament.patent,
        this.fssTrauma.patent,
        this.tablesList.outsource.patent,
        this.manager.patent
      );
      this.newSumWithoutNdc.eaeu = Caclulate.calcNewSumWithoutNdc(
        this.tableListEaeu.fot,
        this.fotFull.eaeu,
        this.ndfl.eaeu,
        this.reglament.eaeu,
        this.fssTrauma.eaeu,
        this.tablesList.outsource.eaeu,
        this.manager.eaeu
      );
      this.newSumWithoutNdc.rf = Caclulate.calcNewSumWithoutNdc(
        this.tableListRf.fot,
        this.fotFull.rf,
        this.ndfl.rf,
        this.reglament.rf,
        this.fssTrauma.rf,
        this.tablesList.outsource.rf,
        this.manager.rf
      );
    },

    calcSalary(){

    },

    calcNdc() {
      this.ndc.patent = Caclulate.calcNdc(this.sumWithoutNdc.patent);
      this.ndc.eaeu = Caclulate.calcNdc(this.sumWithoutNdc.eaeu);
      this.ndc.rf = Caclulate.calcNdc(this.sumWithoutNdc.rf);
    },
    calcSumAndNdc() {
      this.sumAndNdc.patent = Caclulate.calcSumAndNdc(
        this.sumWithoutNdc.patent,
        this.ndc.patent
      );
      this.sumAndNdc.eaeu = Caclulate.calcSumAndNdc(
        this.sumWithoutNdc.eaeu,
        this.ndc.eaeu
      );
      this.sumAndNdc.rf = Caclulate.calcSumAndNdc(
        this.sumWithoutNdc.rf,
        this.ndc.rf
      );
    },
    calcAll() {
      this.calcReglament();
      this.calcEmloyTd();
      this.calcReglamentRemain();
      this.calcPfr();
      this.calcFfomc();
      this.calcFss();
      this.calcFssTrauma();
      this.calcTax();
      this.calcNdfl();
      this.calcManager();
      this.calcFotFull();
      this.calcSumWithoutNdc();
      this.calcNdc();
      this.calcSumAndNdc();
      this.calcNewSumWithoutNdc();
      this.calcReglamentTd();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.checkObj();
      this.calcAll();
    });
  },
};
</script>

<style>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
  text-align: left;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px;
  word-break: normal;
}
.tg .tg-2b7s {
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-bobw {
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-s4mc {
  background-color: #f8cbad;
  text-align: center;
  vertical-align: bottom;
}
.tg .tg-a08a {
  background-color: #f8cbad;
  text-align: left;
  vertical-align: bottom;
}
.tg .tg-j6zm {
  font-weight: bold;
  text-align: left;
  vertical-align: bottom;
}

/* .bgErr {
  background-color: lightcoral;
} */
</style>
