<template>
  <div class="tableOutsource_wrap">
    <table class="tg" style="margin-bottom: 30px">
      <thead>
        <tr>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Статус сотрудника </span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Профессия</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Кол-во человек</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Количество часов в месяц</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Стоимость часа сотрудника</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">ЗП на руки для сотрудника</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">ФОТ</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Регламент</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Управленка</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">НДФЛ</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">ПФР</span>
          </th>
          <th class="tg-r5v8" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <span style="font-weight: bold">ФФОМС</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">ФСС</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">ФСС травм.</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Итого налоги / взносы</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Комиссия </span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">Аутсорсинг</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold"
              >Итого счет для Заказчика без НДС</span
            >
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold">НДС</span>
          </th>
          <th class="tg-r5v8">
            <span style="font-weight: bold"
              >Итого счет для Заказчика с НДС</span
            >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td nowrap class="tg-baqh" rowspan="10">
            <slot>{{ this.tableInfo.name }}</slot>
          </td>
          <td class="tg-baqh" rowspan="10">
            <slot>{{ this.tableInfo.proffession }}</slot>
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            {{ this.outsource.patent }}
          </td>
          <td nowrap class="tg-baqh" rowspan="10">
            <slot>{{ this.hoursInMonth }}</slot>
          </td>
          <td nowrap class="tg-baqh" rowspan="10">
            <slot>{{ formated(this.costHoursEmploy) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(tableInfo.fot) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.fot) }}</slot> ₽
          </td>
          <td nowrap class="tg-1u31" rowspan="5">
            <span>
              <slot>{{ formated(this.reglament) }}</slot> ₽</span
            >
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.manager) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.ndfl) }}</slot> ₽
          </td>
          <td nowrap class="tg-7zrl" rowspan="5">
            <slot>{{ formated(this.pfr + this.pfrRemained) }}</slot> ₽
          </td>
          <td
            nowrap
            class="tg-7zrl"
            rowspan="5"
            v-if="tableInfo.name !== 'ПАТЕНТ'"
          >
            <slot>{{ formated(this.ffomc + this.ffomcRemained) }}</slot> ₽
          </td>
          <td nowrap class="tg-8d8j" rowspan="5">
            <slot>{{ formated(this.fssC) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.fss) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.tax) }}</slot> ₽
          </td>
          <td nowrap class="tg-8d8j" rowspan="5">
            <slot>{{ formated(this.manager * 0.22) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.outsource) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.sumWithoutNdc) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.ndc) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(this.sumAndNdc) }}</slot> ₽
          </td>
        </tr>
        <tr>
          <!-- <td nowrap class="tg-baqh" rowspan="2">
            <slot>{{ formated(this.CONSTS.FOT_FIX) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(this.ndfl) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j">
            <span>22%</span>
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <span>5.1%</span>
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(this.fssC) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(this.fssTrauma) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(this.manager * 0.15) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(this.ndc) }}</slot> ₽
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-8d8j">
            <slot>{{ formated(this.pfr) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <slot>{{ formated(this.ffomc) }}</slot> ₽
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-baqh" rowspan="2">
            <slot>{{ formated(this.reglamentRemain) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j">
            <span>10%</span>
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <span>5%</span>
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-8d8j">
            <slot>{{ formated(this.pfrRemained) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <slot>{{ formated(this.ffomcRemained) }}</slot> ₽
          </td> -->
        </tr>
        <tr>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ tableInfo.countEmpol }}</slot>
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(tableInfo.fot)) }}</slot>
            ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.fot)) }}</slot> ₽
          </td>
          <td nowrap class="tg-8d8j" rowspan="5">
            <span
              ><slot>{{ formated(calcSumEmploy(this.reglament)) }}</slot>
              ₽</span
            >
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.manager)) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.ndfl)) }}</slot> ₽
          </td>
          <td nowrap class="tg-7zrl" rowspan="5">
            <slot>{{
              formated(
                calcSumEmploy(this.pfr) + calcSumEmploy(this.pfrRemained)
              )
            }}</slot>
            ₽
          </td>
          <td
            nowrap
            class="tg-7zrl"
            rowspan="5"
            v-if="tableInfo.name !== 'ПАТЕНТ'"
          >
            <slot>{{
              formated(
                calcSumEmploy(this.ffomc) + calcSumEmploy(this.ffomcRemained)
              )
            }}</slot>
            ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.fssC)) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.fssTrauma)) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot> {{ formated(calcSumEmploy(this.tax)) }} ₽ </slot>
          </td>
          <td nowrap class="tg-8d8j" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.manager) * 0.22) }}</slot>
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.outsource)) }}</slot>
            ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.sumWithoutNdc)) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.ndc)) }}</slot> ₽
          </td>
          <td nowrap class="tg-baqh" rowspan="5">
            <slot>{{ formated(calcSumEmploy(this.sumAndNdc)) }}</slot> ₽
          </td>
        </tr>
        <tr>
          <!-- <td nowrap class="tg-baqh" rowspan="2">
            <slot>{{ formated(calcSumEmploy(this.CONSTS.FOT_FIX)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(calcSumEmploy(this.ndfl)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j">
            <span>22%</span>
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <span>5.1%</span>
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(calcSumEmploy(this.fssC)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(calcSumEmploy(this.fssTrauma)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(calcSumEmploy(this.manager) * 0.22) }}</slot>
          </td> -->
          <!-- <td nowrap class="tg-baqh" rowspan="4">
            <slot>{{ formated(calcSumEmploy(this.ndc)) }}</slot> ₽
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-8d8j">
            <slot>{{ formated(calcSumEmploy(this.pfr)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <slot>{{ formated(calcSumEmploy(this.ffomc)) }}</slot> ₽
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-baqh" rowspan="2">
            <slot>{{ formated(calcSumEmploy(this.reglamentRemain)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j">
            <span>10%</span>
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <span>5%</span>
          </td> -->
        </tr>
        <tr>
          <!-- <td nowrap class="tg-8d8j">
            <slot>{{ formated(calcSumEmploy(this.pfrRemained)) }}</slot> ₽
          </td> -->
          <!-- <td nowrap class="tg-8d8j" v-if="tableInfo.name !== 'ПАТЕНТ'">
            <slot>{{ formated(calcSumEmploy(this.ffomcRemained)) }}</slot> ₽
          </td> -->
        </tr>
      </tbody>
    </table>

    <table class="tg tabletka">
      <thead>
        <tr>
          <th class="tg-bobw" colspan="4">
            <span style="font-weight: bold"
              >ИТОГОВЫЙ РАСЧЕТ: -
              <slot>{{ this.tableInfo.proffession }}</slot></span
            >
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
import Caclulate from "../../../services/calculateNew";

export default {
  props: { tableInfo: Object, outsource: Number, fss: Number },
  data() {
    return {
      CONSTS: {
        FOT_FIX: 19242,
        NDFL: 0.13,
        NDS: 0.2,
        PFR_FIX: 0.22,
        PFR_REMAINDER: 0.1,
        FSS_INSURANCE: 0.018,
        FSS_OTHER: 0.029,
        FFOMC_FIX: 0.051,
        FFOMC_REMAINDER: 0.05,
      },
      fssProc: 1.8,
      hoursInMonth: 0,
      reglament: 0,
      reglamentRemain: 0,
      ndfl: 0,
      fot: 0,
      manager: 0,
      costHoursEmploy: 0,
      pfr: 0,
      ffomc: 0,
      ffomcRemained: 0,
      pfrRemained: 0,
      fssC: 0,
      fssTrauma: 0,
      tax: 0,
      sumWithoutNdc: 0,
      ndc: 0,
      sumAndNdc: 0,
    };
  },

  methods: {
    formated(value) {
      let formatter = new Intl.NumberFormat("ru", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
    hoursInMounth() {
      if (this.tableInfo.days == 22) {
        this.hoursInMonth = Caclulate.hoursInMonth(8, this.tableInfo.days);
      } else if (this.tableInfo.days == 26) {
        this.hoursInMonth = Caclulate.hoursInMonth(12, this.tableInfo.days);
      } else {
        this.hoursInMonth = Caclulate.hoursInMonth(12, this.tableInfo.days);
      }
      // this.hoursInMonth = Caclulate.hoursInMonth(
      //   this.tableInfo.hours,
      //   this.tableInfo.days
      // );
    },
    calcReglament() {
      this.reglament = Caclulate.reglament(this.tableInfo.fot);
    },
    calcReglamentRemain() {
      this.reglamentRemain = Caclulate.reglamentRemain(
        this.reglament,
        this.CONSTS.FOT_FIX
      );
    },
    calcNdfl() {
      this.ndfl = Caclulate.calcNdfl(this.reglament, this.CONSTS.NDFL);
    },
    calcFotFull() {
      this.fot = Caclulate.calcFotFull(this.tableInfo.fot, this.ndfl);
    },
    calcManager() {
      this.manager = Caclulate.calcManager(this.fot, this.reglament);
    },
    calcCostHoursEmploy() {
      this.costHoursEmploy = Caclulate.costHoursEmploy(
        this.fot,
        this.hoursInMonth
      );
    },
    calcPfr() {
      this.pfr = Caclulate.calcPfr(this.CONSTS.FOT_FIX, this.CONSTS.PFR_FIX);

      this.pfrRemained = Caclulate.calcPfr(
        this.reglamentRemain,
        this.CONSTS.PFR_REMAINDER
      );
    },
    calcFfomc() {
      this.ffomc = Caclulate.calcFfomc(
        this.CONSTS.FOT_FIX,
        this.CONSTS.FFOMC_FIX
      );
      this.ffomcRemained = Caclulate.calcFfomc(
        this.reglamentRemain,
        this.CONSTS.FFOMC_REMAINDER
      );
    },
    calcFss() {
      this.fssC = Caclulate.calcFss(this.CONSTS.FOT_FIX, this.tableInfo.name);
    },
    calcFssTrauma() {
      this.fssTrauma = Caclulate.calcNewFssTrauma(this.fot, this.ndfl);
    },
    calcTax() {
      this.tax = Caclulate.calcTax(
        this.pfr,
        this.pfrRemained,
        this.fssC,
        this.fssTrauma,
        this.ffomc,
        this.ffomcRemained,
        this.tableInfo.name
      );
    },
    calcSumWithoutNdc() {
      this.sumWithoutNdc = Caclulate.calcSumWithoutNdcCom(
        this.fot,
        this.tax,
        this.outsource,
        this.manager * 0.22
      );
    },
    calcNdc() {
      this.ndc = Caclulate.calcNdc(this.sumWithoutNdc);
    },
    calcSumAndNdc() {
      this.sumAndNdc = Caclulate.calcSumAndNdc(this.sumWithoutNdc, this.ndc);
    },
    calcSumEmploy(data) {
      return Number(Number(this.tableInfo.countEmpol) * Number(data));
    },
    calcAll() {
      this.hoursInMounth();
      this.calcReglament();
      this.calcReglamentRemain();
      this.calcNdfl();
      this.calcFotFull();
      this.calcManager();
      this.calcCostHoursEmploy();
      this.calcPfr();
      this.calcFfomc();
      this.calcFss();
      this.calcFssTrauma();
      this.calcTax();
      this.calcSumWithoutNdc();
      this.calcNdc();
      this.calcSumAndNdc();
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.calcAll();
      if (this.tableInfo.name !== "ПАТЕНТ") {
        this.fssProc = 2.9;
      }
    });
  },
};
</script>

<style>
.tableOutsource_wrap {
  overflow: hidden;
  overflow-x: scroll;
  margin-bottom: 15px;
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0;
  margin: 0 auto;
  padding-bottom: 30px;
}

.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  overflow: hidden;
  padding: 7px 5px;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 7px 5px;
  word-break: normal;
}
.tg .tg-baqh {
  text-align: center;
  vertical-align: center;
}
.tg .tg-r5v8 {
  background-color: #a2cf6e;
  font-weight: bold;
  text-align: center;
  vertical-align: center;
}
.tg .tg-1u31 {
  text-align: center;
  vertical-align: center;
}
.tg .tg-8d8j {
  text-align: center;
  vertical-align: center;
}
.tg .tg-7zrl {
  text-align: left;
  vertical-align: center;
}
</style>
