<template>
  <div class="form_block">
    <form action="" class="form">
      <div class="calculate_content">
        <div class="left">
          <genefal-info-inputs-vue @upInfo="upInfo" ref="generalInfo" />
          <div class="outstaff-wrapper">

            <amount-emoloyees-inputs-vue
              @upCountEmployees="upCountEmployees"
              ref="amountEmployess"
            />

            <fot-inputs-vue
              :isDisableFot="isDisableFot"
              @upFot="upFot"
              :heading="heading"
              ref="fotInputs"
            />



            <amount-hours-inputs-vue
              :isDisableFot="isDisableFot"
              @upHours="upHours"
              ref="amountHours"
            />
            <amount-days-inputs-vue
              :isDisableFot="isDisableFot"
              @upDays="upDays"
              ref="amountDays"
            />
            <outstaffing-vue
              :isDisableFot="isDisableFot"
              @upOutstaffing="upOutstaffing"
              style="border: none;"
              ref="oustaffInput"
            />
            <fss-input-vue @upFss="upFss" ref="fssInput" style="border: none;" />
            <system-tax-vue @upSystemTax="upSystemTax" />

          </div>

        </div>
        <submit-reset-vue @calc="calculate" @reset="reset" :type="'outstaff'" />
      </div>
    </form>
  </div>
</template>

<script>
import GenefalInfoInputsVue from "../Fields/GeneralInfo-inputs/GeneralInfo-inputs.vue";
import AmountEmoloyeesInputsVue from "../Fields/AmountEmployees-inputs/AmountEmployees-inputs.vue";
import FotInputsVue from "../Fields/Fot/Fot-inputs-outstuff.vue";
import AmountHoursInputsVue from "../Fields/AmountHours-inputs/AmountHours-inputsOutstaffing.vue";
import AmountDaysInputsVue from "../Fields/AmountDays-inputs/AmountDays-inputsOutstaffing.vue";
import FssInputVue from "../Fields/Fss/Fss-input.vue";
import SystemTaxVue from "../Fields/SystemTax/SystemTax.vue";
import OutstaffingVue from "../Fields/Outstaffing/Outstaffing-inputs.vue";
import SubmitResetVue from "../Fields/Submit/SubmitReset.vue";

import Calculate from "../../services/calculate.js";

export default {
  components: {
    GenefalInfoInputsVue,
    AmountEmoloyeesInputsVue,
    FotInputsVue,
    AmountHoursInputsVue,
    AmountDaysInputsVue,
    FssInputVue,
    SystemTaxVue,
    OutstaffingVue,
    SubmitResetVue,
  },
  emits: ["updateParent", "upPopInfo"],

  data() {
    return {
      CONSTS: {
        FOT_FIX: 15279,
        NDFL: 0.13,
        NDS: 0.2,
        PFR_FIX: 0.22,
        PFR_REMAINDER: 0.1,
        FSS_INSURANCE: 0.018,
        FSS_INSURANCE_OTHER: 0.029,
        FFOMC: 0.051,
        FFOMC_2: 0.05,
      },
      caclResults: {
        fotRemainder: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        ndfl: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        pfrFix: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        pfrRemainder: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        ffomc: {
          eaeu: 0,
          rf: 0,
        },
        ffomc2: {
          eaeu: 0,
          rf: 0,
        },
        sumFfomc: {
          eaeu: 0,
          rf: 0,
        },
        sumFfomc2: {
          eaeu: 0,
          rf: 0,
        },
        fssInsurance: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        fssTrauma: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        taxBase: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumWithoutNdc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        ndc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumAndNdc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        salaries: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumFot: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumFotR: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumNdfl: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumPfrFix: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumPfrRemainder: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumFssInsurance: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumFssTrauma: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumTaxBase: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumSumWithoutNdc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumNdc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumSumAndNdc: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        sumSalaries: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
      },
      heading: "Заработная плата сотрудника",
      name: "Не указано",
      project: "Не указано",
      fieldOfActivity: "Не указано",
      countEmployees: {
        countPatent: 1,
        countEaeu: 1,
        countRf: 1,
      },
      isDisableFot: {
        isDisableFotPatent: false,
        isDisableFotEaeu: false,
        isDisableFotRf: false,
      },
      fot: {
        countPatent: 0,
        countEaeu: 0,
        countRf: 0,
      },
      isMrotError: {
        patent: false,
        eaeu: false,
        rf: false,
      },
      hours: {
        countPatent: 8,
        countEaeu: 8,
        countRf: 8,
      },
      days: {
        countPatent: 22,
        countEaeu: 22,
        countRf: 22,
      },
      fss: 0.2,
      selSystemTax: "",
      outstaffing: {
        countPatent: 0,
        countEaeu: 0,
        countRf: 0,
      },
      tables: [],
    };
  },

  watch: {
    countEmployees: {
      deep: true,
      handler(newValue) {
        if (newValue.countPatent == 0) {
          this.isDisableFot.isDisableFotPatent = true;
        } else if (newValue.countPatent > 0) {
          this.isDisableFot.isDisableFotPatent = false;
          this.$emit("upPopInfo", false);
        }
        if (newValue.countEaeu == 0) {
          this.isDisableFot.isDisableFotEaeu = true;
        } else if (newValue.countEaeu > 0) {
          this.isDisableFot.isDisableFotEaeu = false;
          this.$emit("upPopInfo", false);
        }
        if (newValue.countRf == 0) {
          this.isDisableFot.isDisableFotRf = true;
        } else if (newValue.countRf > 0) {
          this.isDisableFot.isDisableFotRf = false;
          this.$emit("upPopInfo", false);
        }
      },
    },
    fot: {
      handler(newValue) {
        if (newValue.countPatent <= 25500) {
          this.isMrotError.eaeu = true;
        }
        if (newValue.countEaeu <= 25500) {
          this.isMrotError.eaeu = true;
        }
        if (newValue.countRf <= 25500) {
          this.isMrotError.rf = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    upCountEmployees(countEmployees) {
      this.countEmployees.countPatent = countEmployees.countPatent;
      this.countEmployees.countEaeu = countEmployees.countEaeu;
      this.countEmployees.countRf = countEmployees.countRf;
    },
    upFot(fot) {
      this.fot.countPatent = fot.patent;
      this.fot.countEaeu = fot.eaeu;
      this.fot.countRf = fot.rf;
    },
    upHours(hours) {
      this.hours.countPatent = hours.countPatent;
      this.hours.countEaeu = hours.countEaeu;
      this.hours.countRf = hours.countRf;
    },
    upDays(days) {
      this.days.countPatent = days.countPatent;
      this.days.countEaeu = days.countEaeu;
      this.days.countRf = days.countRf;
    },
    upFss(fss) {
      this.fss = fss;
    },
    upSystemTax(tax) {
      this.selSystemTax = tax;
    },
    upOutstaffing(outstaffing) {
      this.outstaffing.countPatent = outstaffing.countPatent;
      this.outstaffing.countEaeu = outstaffing.countEaeu;
      this.outstaffing.countRf = outstaffing.countRf;
    },
    upInfo(info) {
      this.name = info.companyName;
      this.project = info.projectName;
      this.fieldOfActivity = info.scopeName;
    },

    caclFot(fotPatent, fotEaeu, fotRF, FOT_FIX) {
      this.caclResults.fotRemainder.patent = Calculate.fotRemained(
        fotPatent,
        FOT_FIX
      );
      this.caclResults.fotRemainder.eaeu = Calculate.fotRemained(
        fotEaeu,
        FOT_FIX
      );
      this.caclResults.fotRemainder.rf = Calculate.fotRemained(fotRF, FOT_FIX);
      //   sumFot
      this.caclResults.sumFot.patent = Calculate.sumEmploys(
        this.countEmployees.countPatent,
        fotPatent
      );
      this.caclResults.sumFot.eaeu = Calculate.sumEmploys(
        this.countEmployees.countEaeu,
        fotEaeu
      );
      this.caclResults.sumFot.rf = Calculate.sumEmploys(
        this.countEmployees.countRf,
        fotRF
      );
      //   sumFotR
      this.caclResults.sumFotR.patent = Calculate.sumEmploys(
        this.countEmployees.countPatent,
        this.caclResults.fotRemainder.patent
      );
      this.caclResults.sumFotR.eaeu = Calculate.sumEmploys(
        this.countEmployees.countEaeu,
        this.caclResults.fotRemainder.eaeu
      );
      this.caclResults.sumFotR.rf = Calculate.sumEmploys(
        this.countEmployees.countRf,
        this.caclResults.fotRemainder.rf
      );
    },
    calcNdfl(fotPatent, fotEaeu, fotRF, NDFL) {
      this.caclResults.ndfl.patent = Calculate.calcNdfl(fotPatent, NDFL);
      this.caclResults.ndfl.eaeu = Calculate.calcNdfl(fotEaeu, NDFL);
      this.caclResults.ndfl.rf = Calculate.calcNdfl(fotRF, NDFL);

      this.caclResults.sumNdfl.patent = Calculate.sumEmploys(
        this.caclResults.ndfl.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumNdfl.eaeu = Calculate.sumEmploys(
        this.caclResults.ndfl.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumNdfl.rf = Calculate.sumEmploys(
        this.caclResults.ndfl.rf,
        this.countEmployees.countRf
      );
    },
    calcPfr(FOT_FIX, PFR_FIX) {
      this.caclResults.pfrFix.patent = Calculate.calcPfr(FOT_FIX, PFR_FIX);
      this.caclResults.pfrFix.eaeu = Calculate.calcPfr(FOT_FIX, PFR_FIX);
      this.caclResults.pfrFix.rf = Calculate.calcPfr(FOT_FIX, PFR_FIX);
      // sum
      this.caclResults.sumPfrFix.patent = Calculate.sumEmploys(
        this.caclResults.pfrFix.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumPfrFix.eaeu = Calculate.sumEmploys(
        this.caclResults.pfrFix.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumPfrFix.rf = Calculate.sumEmploys(
        this.caclResults.pfrFix.rf,
        this.countEmployees.countRf
      );
    },
    calcPfrRemainer(PFR_REMAINDER) {
      this.caclResults.pfrRemainder.patent = Calculate.calcPfr(
        this.caclResults.fotRemainder.patent,
        PFR_REMAINDER
      );
      this.caclResults.pfrRemainder.eaeu = Calculate.calcPfr(
        this.caclResults.fotRemainder.eaeu,
        PFR_REMAINDER
      );
      this.caclResults.pfrRemainder.rf = Calculate.calcPfr(
        this.caclResults.fotRemainder.rf,
        PFR_REMAINDER
      );

      // sum
      this.caclResults.sumPfrRemainder.patent = Calculate.sumEmploys(
        this.caclResults.pfrRemainder.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumPfrRemainder.eaeu = Calculate.sumEmploys(
        this.caclResults.pfrRemainder.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumPfrRemainder.rf = Calculate.sumEmploys(
        this.caclResults.pfrRemainder.rf,
        this.countEmployees.countRf
      );
    },

    calcFssOutstaffPatent(FOT_FIX, FSS_INSURANCE) {
      this.caclResults.fssInsurance.patent = Calculate.calcFssOutstaff(
        FOT_FIX,
        FSS_INSURANCE
      );
      this.caclResults.sumFssInsurance.patent = Calculate.sumEmploys(
        this.caclResults.fssInsurance.patent,
        this.countEmployees.countPatent
      );
    },
    calcFssOutstaffOther(FOT_FIX, FSS_INSURANCE) {
      this.caclResults.fssInsurance.eaeu = Calculate.calcFssOutstaff(
        FOT_FIX,
        FSS_INSURANCE
      );
      this.caclResults.fssInsurance.rf = Calculate.calcFssOutstaff(
        FOT_FIX,
        FSS_INSURANCE
      );
      this.caclResults.sumFssInsurance.eaeu = Calculate.sumEmploys(
        this.caclResults.fssInsurance.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumFssInsurance.rf = Calculate.sumEmploys(
        this.caclResults.fssInsurance.rf,
        this.countEmployees.countRf
      );
    },
    calcFssTraumvOutstaff(fotPatent, fotEaeu, fotRF, fss) {
      fss = fss / 100;
      this.caclResults.fssTrauma.patent = Calculate.calcFssOutstaff(
        fotPatent,
        fss
      );
      this.caclResults.fssTrauma.eaeu = Calculate.calcFssOutstaff(fotEaeu, fss);
      this.caclResults.fssTrauma.rf = Calculate.calcFssOutstaff(fotRF, fss);
      // sum
      this.caclResults.sumFssTrauma.patent = Calculate.sumEmploys(
        this.caclResults.fssTrauma.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumFssTrauma.eaeu = Calculate.sumEmploys(
        this.caclResults.fssTrauma.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumFssTrauma.rf = Calculate.sumEmploys(
        this.caclResults.fssTrauma.rf,
        this.countEmployees.countRf
      );
    },
    calcFfomc(FOT_FIX, FFOMC) {
      this.caclResults.ffomc.eaeu = Calculate.calcFfomc(FOT_FIX, FFOMC);
      this.caclResults.ffomc.rf = Calculate.calcFfomc(FOT_FIX, FFOMC);

      this.caclResults.sumFfomc.eaeu = Calculate.sumEmploys(
        this.caclResults.ffomc.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumFfomc.rf = Calculate.sumEmploys(
        this.caclResults.ffomc.rf,
        this.countEmployees.countRf
      );
    },
    calcFfomc2(FFOMC) {
      this.caclResults.ffomc2.eaeu = Calculate.calcFfomc(
        this.caclResults.fotRemainder.eaeu,
        FFOMC
      );
      this.caclResults.ffomc2.rf = Calculate.calcFfomc(
        this.caclResults.fotRemainder.rf,
        FFOMC
      );

      this.caclResults.sumFfomc2.eaeu = Calculate.sumEmploys(
        this.caclResults.ffomc2.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumFfomc2.rf = Calculate.sumEmploys(
        this.caclResults.ffomc2.rf,
        this.countEmployees.countRf
      );
    },
    calcTax() {
      this.caclResults.taxBase.patent = Calculate.calcTaxOutstaff(
        this.caclResults.pfrRemainder.patent,
        this.caclResults.pfrFix.patent,
        this.caclResults.fssInsurance.patent,
        this.caclResults.fssTrauma.patent,
        0,
        0
      );
      this.caclResults.taxBase.eaeu = Calculate.calcTaxOutstaff(
        this.caclResults.pfrRemainder.eaeu,
        this.caclResults.pfrFix.eaeu,
        this.caclResults.fssInsurance.eaeu,
        this.caclResults.fssTrauma.eaeu,
        this.caclResults.ffomc.eaeu,
        this.caclResults.ffomc2.eaeu
      );
      this.caclResults.taxBase.rf = Calculate.calcTaxOutstaff(
        this.caclResults.pfrRemainder.rf,
        this.caclResults.pfrFix.rf,
        this.caclResults.fssInsurance.rf,
        this.caclResults.fssTrauma.rf,
        this.caclResults.ffomc.rf,
        this.caclResults.ffomc2.rf
      );

      this.caclResults.sumTaxBase.patent = Calculate.sumEmploys(
        this.caclResults.taxBase.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumTaxBase.eaeu = Calculate.sumEmploys(
        this.caclResults.taxBase.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumTaxBase.rf = Calculate.sumEmploys(
        this.caclResults.taxBase.rf,
        this.countEmployees.countRf
      );
    },
    calcSumWithoutNdc() {
      this.caclResults.sumWithoutNdc.patent = Calculate.calcSumWithoutNdc(
        this.fot.countPatent,
        this.caclResults.taxBase.patent,
        this.outstaffing.countPatent
      );
      this.caclResults.sumWithoutNdc.eaeu = Calculate.calcSumWithoutNdc(
        this.fot.countEaeu,
        this.caclResults.taxBase.eaeu,
        this.outstaffing.countEaeu
      );
      this.caclResults.sumWithoutNdc.rf = Calculate.calcSumWithoutNdc(
        this.fot.countRf,
        this.caclResults.taxBase.rf,
        this.outstaffing.countRf
      );
      this.caclResults.sumSumWithoutNdc.patent = Calculate.sumEmploys(
        this.caclResults.sumWithoutNdc.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumSumWithoutNdc.eaeu = Calculate.sumEmploys(
        this.caclResults.sumWithoutNdc.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumSumWithoutNdc.rf = Calculate.sumEmploys(
        this.caclResults.sumWithoutNdc.rf,
        this.countEmployees.countRf
      );
    },
    calcNdc() {
      if (this.selSystemTax == "nds20") {
        this.caclResults.ndc.patent = Calculate.calcNdc(
          this.caclResults.sumWithoutNdc.patent
        );
        this.caclResults.ndc.eaeu = Calculate.calcNdc(
          this.caclResults.sumWithoutNdc.eaeu
        );
        this.caclResults.ndc.rf = Calculate.calcNdc(
          this.caclResults.sumWithoutNdc.rf
        );
        // sum
        this.caclResults.sumNdc.patent = Calculate.sumEmploys(
          this.caclResults.ndc.patent,
          this.countEmployees.countPatent
        );
        this.caclResults.sumNdc.eaeu = Calculate.sumEmploys(
          this.caclResults.ndc.eaeu,
          this.countEmployees.countEaeu
        );
        this.caclResults.sumNdc.rf = Calculate.sumEmploys(
          this.caclResults.ndc.rf,
          this.countEmployees.countRf
        );
      }
    },
    calcSumAndNdc() {
      if (this.selSystemTax == "nds20") {
        this.caclResults.sumAndNdc.patent = Calculate.calcSumAndNdc(
          this.caclResults.sumWithoutNdc.patent,
          this.caclResults.ndc.patent
        );
        this.caclResults.sumAndNdc.eaeu = Calculate.calcSumAndNdc(
          this.caclResults.sumWithoutNdc.eaeu,
          this.caclResults.ndc.eaeu
        );
        this.caclResults.sumAndNdc.rf = Calculate.calcSumAndNdc(
          this.caclResults.sumWithoutNdc.rf,
          this.caclResults.ndc.rf
        );
        this.caclResults.sumSumAndNdc.patent = Calculate.sumEmploys(
          this.caclResults.sumAndNdc.patent,
          this.countEmployees.countPatent
        );
        this.caclResults.sumSumAndNdc.eaeu = Calculate.sumEmploys(
          this.caclResults.sumAndNdc.eaeu,
          this.countEmployees.countEaeu
        );
        this.caclResults.sumSumAndNdc.rf = Calculate.sumEmploys(
          this.caclResults.sumAndNdc.rf,
          this.countEmployees.countRf
        );
      }
    },
    calcSalaries(fotPatent, fotEaeu, fotRF) {
      this.caclResults.salaries.patent = Calculate.calcSalaries(
        fotPatent,
        this.caclResults.ndfl.patent
      );
      this.caclResults.salaries.eaeu = Calculate.calcSalaries(
        fotEaeu,
        this.caclResults.ndfl.eaeu
      );
      this.caclResults.salaries.rf = Calculate.calcSalaries(
        fotRF,
        this.caclResults.ndfl.rf
      );
      // sum
      this.caclResults.sumSalaries.patent = Calculate.sumEmploys(
        this.caclResults.salaries.patent,
        this.countEmployees.countPatent
      );
      this.caclResults.sumSalaries.eaeu = Calculate.sumEmploys(
        this.caclResults.salaries.eaeu,
        this.countEmployees.countEaeu
      );
      this.caclResults.sumSalaries.rf = Calculate.sumEmploys(
        this.caclResults.salaries.rf,
        this.countEmployees.countRf
      );
    },

    calculate() {
      const fotPatent = Number(this.fot.countPatent);
      const fotEaeu = Number(this.fot.countEaeu);
      const fotRF = Number(this.fot.countRf);

      const FOT_FIX = this.CONSTS.FOT_FIX;
      const NDFL = this.CONSTS.NDFL;
      const PFR_FIX = this.CONSTS.PFR_FIX;
      const PFR_REMAINDER = this.CONSTS.PFR_REMAINDER;
      const FSS_INSURANCE = this.CONSTS.FSS_INSURANCE;
      const FSS_INSURANCE_OTHER = this.CONSTS.FSS_INSURANCE_OTHER;
      const FFOMC = this.CONSTS.FFOMC;
      const FFOMC_2 = this.CONSTS.FFOMC_2;

      this.caclFot(fotPatent, fotEaeu, fotRF, FOT_FIX);
      this.calcNdfl(fotPatent, fotEaeu, fotRF, NDFL);
      this.calcPfr(FOT_FIX, PFR_FIX);
      this.calcPfrRemainer(PFR_REMAINDER);
      this.calcFssOutstaffPatent(FOT_FIX, FSS_INSURANCE);
      this.calcFssOutstaffOther(FOT_FIX, FSS_INSURANCE_OTHER);
      this.calcFssTraumvOutstaff(fotPatent, fotEaeu, fotRF, this.fss);
      this.calcFfomc(FOT_FIX, FFOMC);
      this.calcFfomc2(FFOMC_2);
      this.calcTax();
      this.calcSumWithoutNdc();
      this.calcNdc();
      this.calcSumAndNdc();
      this.calcSalaries(fotPatent, fotEaeu, fotRF);

      this.pushTableArray();

      this.$emit("updateParent", {
        name: this.name,
        project: this.project,
        fieldOfActivity: this.fieldOfActivity,
        tablesList: this.tables,
        isShow: this.tables.length,
      });
    },

    pushTableArray() {
      this.tables.length = 0;
      if (this.countEmployees.countPatent) {
        let table = {
          name: "ПАТЕНТ",
          countEmpol: this.countEmployees.countPatent,
          fot: this.fot.countPatent,
          hours: this.hours.countPatent,
          days: this.days.countPatent,
          systemTax: this.selSystemTax,
          fssTraumaProc: this.fss,
          outstaffing: this.outstaffing.countPatent,
          fotRemainder: this.caclResults.fotRemainder.patent,
          ndfl: this.caclResults.ndfl.patent,
          pfrFix: this.caclResults.pfrFix.patent,
          pfrRemainder: this.caclResults.pfrRemainder.patent,
          fssInsurance: this.caclResults.fssInsurance.patent,
          fssTrauma: this.caclResults.fssTrauma.patent,
          taxBase: this.caclResults.taxBase.patent,
          sumWithoutNdc: this.caclResults.sumWithoutNdc.patent,
          ndc: this.caclResults.ndc.patent,
          sumAndNdc: this.caclResults.sumAndNdc.patent,
          salaries: this.caclResults.salaries.patent,
          sumFot: this.caclResults.sumFot.patent,
          sumFotR: this.caclResults.sumFotR.patent,
          sumNdfl: this.caclResults.sumNdfl.patent,
          sumPfrFix: this.caclResults.sumPfrFix.patent,
          sumPfrRemainder: this.caclResults.sumPfrRemainder.patent,
          sumFssInsurance: this.caclResults.sumFssInsurance.patent,
          sumFssTrauma: this.caclResults.sumFssTrauma.patent,
          sumTaxBase: this.caclResults.sumTaxBase.patent,
          sumSumAndNdc: this.caclResults.sumSumAndNdc.patent,
          sumSalaries: this.caclResults.sumSalaries.patent,
          sumSumWithoutNdc: this.caclResults.sumSumWithoutNdc.patent,
          sumNdc: this.caclResults.sumNdc.patent,
        };
        this.tables.push(table);
      }
      if (this.countEmployees.countEaeu) {
        let table = {
          name: "ЕАЭС",
          countEmpol: this.countEmployees.countEaeu,
          fot: this.fot.countEaeu,
          hours: this.hours.countEaeu,
          days: this.days.countEaeu,
          systemTax: this.selSystemTax,
          outstaffing: this.outstaffing.countEaeu,
          fssTraumaProc: this.fss,
          fotRemainder: this.caclResults.fotRemainder.eaeu,
          ffomc: this.caclResults.ffomc.eaeu,
          sumFfomc: this.caclResults.sumFfomc.eaeu,
          sumFfomc2: this.caclResults.sumFfomc2.eaeu,
          ffomc2: this.caclResults.ffomc2.eaeu,
          ndfl: this.caclResults.ndfl.eaeu,
          pfrFix: this.caclResults.pfrFix.eaeu,
          pfrRemainder: this.caclResults.pfrRemainder.eaeu,
          fssInsurance: this.caclResults.fssInsurance.eaeu,
          fssTrauma: this.caclResults.fssTrauma.eaeu,
          taxBase: this.caclResults.taxBase.eaeu,
          sumWithoutNdc: this.caclResults.sumWithoutNdc.eaeu,
          ndc: this.caclResults.ndc.eaeu,
          sumAndNdc: this.caclResults.sumAndNdc.eaeu,
          salaries: this.caclResults.salaries.eaeu,
          sumFot: this.caclResults.sumFot.eaeu,
          sumFotR: this.caclResults.sumFotR.eaeu,
          sumNdfl: this.caclResults.sumNdfl.eaeu,
          sumPfrFix: this.caclResults.sumPfrFix.eaeu,
          sumPfrRemainder: this.caclResults.sumPfrRemainder.eaeu,
          sumFssInsurance: this.caclResults.sumFssInsurance.eaeu,
          sumFssTrauma: this.caclResults.sumFssTrauma.eaeu,
          sumTaxBase: this.caclResults.sumTaxBase.eaeu,
          sumSumAndNdc: this.caclResults.sumSumAndNdc.eaeu,
          sumSalaries: this.caclResults.sumSalaries.eaeu,
          sumSumWithoutNdc: this.caclResults.sumSumWithoutNdc.eaeu,
          sumNdc: this.caclResults.sumNdc.eaeu,
        };
        this.tables.push(table);
      }
      if (this.countEmployees.countRf) {
        let table = {
          name: "РФ",
          countEmpol: this.countEmployees.countRf,
          fot: this.fot.countRf,
          hours: this.hours.countRf,
          days: this.days.countRf,
          systemTax: this.selSystemTax,
          outstaffing: this.outstaffing.countRf,
          fssTraumaProc: this.fss,
          fotRemainder: this.caclResults.fotRemainder.rf,
          ffomc: this.caclResults.ffomc.rf,
          ffomc2: this.caclResults.ffomc2.rf,
          sumFfomc: this.caclResults.sumFfomc.rf,
          sumFfomc2: this.caclResults.sumFfomc2.rf,
          ndfl: this.caclResults.ndfl.rf,
          pfrFix: this.caclResults.pfrFix.rf,
          pfrRemainder: this.caclResults.pfrRemainder.rf,
          fssInsurance: this.caclResults.fssInsurance.rf,
          fssTrauma: this.caclResults.fssTrauma.rf,
          taxBase: this.caclResults.taxBase.rf,
          sumWithoutNdc: this.caclResults.sumWithoutNdc.rf,
          ndc: this.caclResults.ndc.rf,
          sumAndNdc: this.caclResults.sumAndNdc.rf,
          salaries: this.caclResults.salaries.rf,
          sumFot: this.caclResults.sumFot.rf,
          sumFotR: this.caclResults.sumFotR.rf,
          sumNdfl: this.caclResults.sumNdfl.rf,
          sumPfrFix: this.caclResults.sumPfrFix.rf,
          sumPfrRemainder: this.caclResults.sumPfrRemainder.rf,
          sumFssInsurance: this.caclResults.sumFssInsurance.rf,
          sumFssTrauma: this.caclResults.sumFssTrauma.rf,
          sumTaxBase: this.caclResults.sumTaxBase.rf,
          sumSumAndNdc: this.caclResults.sumSumAndNdc.rf,
          sumSalaries: this.caclResults.sumSalaries.rf,
          sumSumWithoutNdc: this.caclResults.sumSumWithoutNdc.rf,
          sumNdc: this.caclResults.sumNdc.rf,
        };
        this.tables.push(table);
      }
      if (
        this.tables.length == 0 &&
        !this.isDisableFotPatent &&
        !this.isDisableFotEaeu &&
        !this.isDisableFotRf
      ) {
        this.$emit("upPopInfo", true);
      } else {
        this.$emit("upPopInfo", false);
      }
    },

    reset() {
      this.$refs.generalInfo.resetGeneralInfo();
      this.$refs.amountEmployess.resetValues();
      this.$refs.fotInputs.resetValues();
      this.$refs.amountHours.resetValues();
      this.$refs.amountDays.resetValues();
      this.$refs.fssInput.resetValue();
      this.$refs.oustaffInput.resetValues();
      this.tables = [];
      this.$emit("updateParent", {
        name: this.name,
        project: this.project,
        fieldOfActivity: this.fieldOfActivity,
        tablesList: this.tables,
        isShow: this.tables.length,
      });
    },
  },

  beforeMount() {
    this.$nextTick(function () {
      this.$refs.amountEmployess.initialValueOutsource();
    });
  },
};
</script>

<style>

.outstaff-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media screen and (max-width: 768px) {
  .outstaff-wrapper{
    display: block;
  }
}
</style>
