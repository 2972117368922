<template>
  <div class="left_block" style="border: none;">
    <div class="left_block__inner">
      <h3>Колличество сотрудников</h3>
      <div class="checkbox_wrapper amound" style="display: grid;grid-template-rows: 1fr 1fr 1fr; justify-content: center;">
        <amount-patent-vue
          @updatePatent="updateCountPatent"
          ref="amountPatent"
        />
        <amount-eaeu-vue @updateEaeu="updateCountEaeu" ref="amountEaeu" />
        <amount-rf-vue @updateRf="updateCountRf" ref="amountRf" />
      </div>
      <div class="important_info">
        Укажите кол-во предполагаемых сотрудников, если сотрудников нет,
        необходимо указать 0.
      </div>
    </div>
  </div>
</template>

<script>
import AmountEaeuVue from "./Amount-eaeu.vue";
import AmountPatentVue from "./Amount-patent.vue";
import AmountRfVue from "./Amount-rf.vue";
export default {
  components: {
    AmountEaeuVue,
    AmountPatentVue,
    AmountRfVue,
  },

  data() {
    return {
      countEmployees: {
        countPatent: 0,
        countEaeu: 0,
        countRf: 0,
      },
    };
  },

  methods: {
    updateCountPatent(count) {
      this.countEmployees.countPatent = count;
      this.$emit("upCountEmployees", this.countEmployees);
      this.$emit("disabledFotPatent", this.countEmployees);
    },
    updateCountEaeu(count) {
      this.countEmployees.countEaeu = count;
      this.$emit("upCountEmployees", this.countEmployees);
      this.$emit("disabledFotPatent", this.countEmployees);
    },
    updateCountRf(count) {
      this.countEmployees.countRf = count;
      this.$emit("upCountEmployees", this.countEmployees);
      this.$emit("disabledFotPatent", this.countEmployees);
    },
    initialValueOutsource() {
      this.$refs.amountPatent.initialValueOutsource();
      this.$refs.amountEaeu.initialValueOutsource();
      this.$refs.amountRf.initialValueOutsource();
    },
    resetValues() {
      this.$refs.amountPatent.resetValue();
      this.$refs.amountEaeu.resetValue();
      this.$refs.amountRf.resetValue();
    },
    resetValuesOutsource() {
      this.$refs.amountPatent.resetValueOutsource();
      this.$refs.amountEaeu.resetValueOutsource();
      this.$refs.amountRf.resetValueOutsource();
    },
  },
};
</script>

<style></style>
