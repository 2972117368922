<template>
  <label class="input_calc">
    <!-- <input
      type="number"
      min="0"
      v-model="countEaeu"
      @input="updateValue"
      :disabled="isDisableFotEaeu"
      required
      placeholder="0"
      class="sum-staff__eaeu"
    /> -->
    <span>EAЭC</span>
    <select required v-model="countEaeu" @change="updateValue" :disabled="isDisableFotEaeu">
        <option disabled value="">График работы</option>
        <option value=22>5/2</option>
        <option value=26>6/1</option>
        <option value=15>2/2</option>
      </select>
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  props: {
    isDisableFotEaeu: {
      type: Boolean,
    },
  },
  data() {
    return {
      countEaeu: 22,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateEaeu", this.countEaeu);
    },
    resetValue() {
      this.countEaeu = 22;
      this.$emit("updateEaeu", this.countEaeu);
    },
  },
};
</script>

<style>
select{
  font-size: 21px;
  width: 108px;
  height: 50px;
}
</style>
