<template>
  <label class="input_calc">
    <input
      type="number"
      min="0"
      v-model="countRf"
      @input="updateValue"
      :disabled="isDisableFotRf"
      required
      placeholder="0"
      class="sum-staff__rf"
    />
    <span>РФ</span>
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  props: {
    isDisableFotRf: {
      type: Boolean,
    },
  },
  data() {
    return {
      countRf: 8,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateRf", this.countRf);
    },
    resetValue() {
      this.countRf = 8;
      this.$emit("updateRf", this.countRf);
    },
  },
};
</script>

<style></style>
