<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>Список профессий</h3>
      <div class="proffessionList" v-if="professionList.length">
        <div
          class="proffessionItem outsource-bg"
          v-for="item in professionList"
          :key="item"
          :id="item.profession + '-' + professionList.length"
        >
          {{ item.profession }}
        </div>
      </div>
      <div v-if="!professionList.length" style="text-align: center">
        список профессий пуст
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    professionList: Object,
  },
};
</script>

<style>
.proffessionList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 15px;
}
.proffessionItem {
  padding: 5px 15px;
  color: #fff;
}
</style>
