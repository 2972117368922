<template>
  <div class="left_block">
    <div class="left_block__inner">
      <h3>Аутсорсинг</h3>
      <div class="checkbox_wrapper amound">
        <!-- <label class="input_calc">
          <input
            type="number"
            min="0"
            v-model="outsource"
            @input="updateValue"
            required
            placeholder="0"
            class="sum-staff__eaeu"
          /> -->
          <select required v-model="outsource" @change="updateValue" :disabled="isDisableFotPatent">
            <option disabled value="">График работы</option>
            <option value=5000>5000</option>
            <option value=10000>10000</option>
            <option value=15000>15000</option>
          </select>
          <span class="error"></span>
      </div>
      <div class="important_info">вознаграждение менеджера</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OutstaffingVue",

  data() {
    return {
      outsource: 0,
    };
  },

  methods: {
    updateValue() {
      this.$emit("upOutsource", this.outsource);
    },
    resetValue() {
      this.outsource = 0;
      this.$emit("upOutsource", this.outsource);
    },
  },
};
</script>

<style></style>
