<template>
  <label class="input_calc">
    <Transition name="slide-fade">
      <span
        class="error error-mrot"
        v-if="this.eaeu <= 25499 && !isDisableFotEaeu"
        >Внимание, вы указали ЗП меньше МРОТ. <br /><br />Минимальная заработная плата по Санкт-Петербургу 25 500 рублей.
      </span>
    </Transition>
    <span>ЕАЭС</span>
    <input
      :disabled="isDisableFotEaeu"
      type="number"
      min="15279"
      v-model="eaeu"
      @input="updateValue"
      required
      placeholder="0"
      class="wage-level__patent"
      id="inpFot__eaeu"
    />

  </label>
</template>

<script>
export default {
  props: {
    isDisableFotEaeu: {
      type: Boolean,
    },
  },

  data() {
    return {
      eaeu: null,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateFotEaeu", this.eaeu);
    },
    resetValue() {
      this.eaeu = 0;
      this.$emit("updateFotEaeu", this.eaeu);
    },
  },
};
</script>

<style></style>
