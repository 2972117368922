<template>
    <table
      class="tg tg-hor"
      ref="tablik"
    >
      <thead>
        <tr>
          <th class="tg-y21g">Статус сотрудника</th>
          <th class="tg-y21g">Кол-во человек</th>
          <th class="tg-y21g">Кол-во отработанного времени</th>
          <th class="tg-y21g">ФОТ</th>
          <th class="tg-y21g">НДФЛ</th>
          <th class="tg-y21g" v-if="this.tablesList.name !== 'ПАТЕНТ'">
            ФФОМС
          </th>
          <th class="tg-y21g">ПФР</th>
          <th class="tg-y21g">ФСС страх.</th>
          <th class="tg-y21g">ФСС травм.</th>
          <th class="tg-y21g">Налоговая база <span style="color:red; font-size: 16px;">*</span></th>
          <th class="tg-y21g">Ставка аутстаф.</th>
          <th class="tg-y21g">Сумма счета без НДС</th>
          <th class="tg-y21g" v-if="this.tablesList.systemTax == 'nds20'">
            НДС
          </th>
          <th class="tg-y21g" v-if="this.tablesList.systemTax == 'nds20'">
            Сумма счета<br />с НДС
          </th>
          <th class="tg-y21g">ЗП к выплате на карту</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="tg-0lax" rowspan="2">
            <slot>{{ this.tablesList.name }}</slot>
          </td>
          <td class="tg-0lax" >1</td>
          <td class="tg-0lax" rowspan="2" >
            <slot>{{ this.tablesList.hours * this.tablesList.days  }}</slot>
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.fot) }}</slot> ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.ndfl) }}</slot> ₽
          </td>
          <td
            class="tg-0lax"
            v-if="this.tablesList.name !== 'ПАТЕНТ'"  >
            <!-- <slot>{{ formated(this.tablesList.ffomc + this.tablesList.ffomc2) }}</slot> -->
            <slot v-if="(this.tablesList.fot < 15278)">{{ formated(this.tablesList.fot *0.051) }}</slot>
            <slot v-else>{{ formated(this.tablesList.ffomc + this.tablesList.ffomc2) }}</slot>
          ₽</td>
          <td class="tg-0lax" >
            <slot v-if="(this.tablesList.fot < 15278)">{{ formated(this.tablesList.fot * 0.22) }}</slot>
            <slot v-else>{{ formated(this.tablesList.pfrFix + this.tablesList.pfrRemainder) }}</slot>
            ₽</td>
          <td class="tg-0lax" >
            <slot v-if="(this.tablesList.name == 'ПАТЕНТ')&&(this.tablesList.fot < 15279)">{{ formated(this.tablesList.fot / 100 * 1.8) }}</slot>
            <slot v-else-if="(this.tablesList.name == 'ПАТЕНТ')&&(this.tablesList.fot >= 15279)">{{ formated(15279 / 100 * 1.8) }}</slot>
            <slot v-else-if="(this.tablesList.name != 'ПАТЕНТ')&&(this.tablesList.fot >= 15279)">{{ formated(15279 / 100 *2.9) }}</slot>
            <slot v-else-if="(this.tablesList.name != 'ПАТЕНТ')&&(this.tablesList.fot < 15279)">{{ formated(15279 / 100 *2.9) }}</slot>
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.fssTrauma) }}</slot> ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name == 'ПАТЕНТ'">
            <!--qwe <slot>{{ formated(this.tablesList.taxBase) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *1.8)+this.tablesList.fssTrauma) }}</slot>
            <slot v-else>{{ formated(this.tablesList.taxBase) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name !== 'ПАТЕНТ'">
            <!-- <slot>{{ formated(this.tablesList.taxBase) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.fot *0.051)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *2.9)+this.tablesList.fssTrauma) }}</slot>
            <slot v-else>{{ formated(this.tablesList.taxBase) }}</slot>
            ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.outstaffing) }}</slot> ₽
          </td>
          <td class="tg-0lax"
          v-if="this.tablesList.name == 'ПАТЕНТ'" >
            <!-- <slot>{{ formated(this.tablesList.sumWithoutNdc) }}</slot>  -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.fot)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *1.8)+this.tablesList.fssTrauma+this.tablesList.outstaffing) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumWithoutNdc) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
          v-if="this.tablesList.name !== 'ПАТЕНТ'" >
            <!-- <slot>{{ formated(this.tablesList.sumWithoutNdc) }}</slot>  -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.fot)+((this.tablesList.fot *0.051)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *2.9)+this.tablesList.fssTrauma)+this.tablesList.outstaffing) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumWithoutNdc) }}</slot>
            ₽
          </td>

          <td class="tg-0lax"
          v-if="(this.tablesList.name == 'ПАТЕНТ')&&(this.tablesList.systemTax == 'nds20')" >
            <slot v-if="this.tablesList.fot < 15278">{{ formated(((this.tablesList.fot)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *1.8)+this.tablesList.fssTrauma+this.tablesList.outstaffing) * 0.2) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumWithoutNdc * 0.2) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
          v-if="(this.tablesList.name !== 'ПАТЕНТ')&&(this.tablesList.systemTax == 'nds20')" >
            <!-- <slot>{{ formated(this.tablesList.sumWithoutNdc) }}</slot>  -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(((this.tablesList.fot)+((this.tablesList.fot *0.051)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *2.9)+this.tablesList.fssTrauma)+this.tablesList.outstaffing)* 0.2) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumWithoutNdc * 0.2) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="(this.tablesList.systemTax == 'nds20')&&(this.tablesList.name == 'ПАТЕНТ')">
            <!-- <slot>{{ formated(this.tablesList.sumAndNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(((this.tablesList.fot)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *1.8)+this.tablesList.fssTrauma+this.tablesList.outstaffing)*1.2) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumAndNdc) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="(this.tablesList.systemTax == 'nds20')&&(this.tablesList.name !== 'ПАТЕНТ')">
            <!-- <slot>{{ formated(this.tablesList.sumAndNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(((this.tablesList.fot)+((this.tablesList.fot *0.051)+(this.tablesList.fot *0.22)+(this.tablesList.fot / 100 *2.9)+this.tablesList.fssTrauma)+this.tablesList.outstaffing)*1.2) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumAndNdc) }}</slot>
            ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.salaries) }}</slot> ₽
          </td>
        </tr>
        <!--
        <tr>
          <td
            class="tg-0lax"
            v-if="this.tablesList.systemTax == 'nds20'"
          >
            <slot>{{ formated(this.tablesList.ndc) }}</slot> ₽
          </td>
        </tr> -->

        <tr>
          <td class="tg-0lax" >
            <slot>{{ this.tablesList.countEmpol }}</slot>
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.sumFot) }}</slot> ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.sumNdfl) }}</slot>
            ₽
          </td>
          <td
            class="tg-0lax"
            v-if="this.tablesList.name !== 'ПАТЕНТ'"

          ><slot>{{ formated(this.tablesList.sumFfomc + this.tablesList.sumFfomc2) }}</slot> ₽</td>
          <td class="tg-0lax" >
            <!-- <slot>{{ formated(this.tablesList.sumPfrFix + this.tablesList.sumPfrRemainder) }}</slot>  -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(this.tablesList.sumFot *0.22) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumPfrFix + this.tablesList.sumPfrRemainder) }}</slot>
          ₽</td>
          <td class="tg-0lax" >
            <!-- <slot>{{ formated(this.tablesList.sumFot / 100 * 1.8) }}</slot> -->
            <slot v-if="this.tablesList.name == 'ПАТЕНТ'">{{ formated(this.tablesList.sumFot / 100 *1.8) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumFot / 100 *2.9) }}</slot>
            ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.sumFssTrauma) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name == 'ПАТЕНТ'" >
            <!-- <slot>{{ formated(this.tablesList.sumTaxBase) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 1.8)+this.tablesList.sumFssTrauma) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumTaxBase) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name !== 'ПАТЕНТ'" >
            <!-- <slot>{{ formated(this.tablesList.sumTaxBase) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot *0.051)+(this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 2.9)+this.tablesList.sumFssTrauma) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumTaxBase) }}</slot>
            ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{
              formated(
                Number(this.tablesList.outstaffing) *
                  Number(this.tablesList.countEmpol)
              )
            }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name == 'ПАТЕНТ'">
            <!-- <slot>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(this.tablesList.sumFot + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 1.8)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)) ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="this.tablesList.name !== 'ПАТЕНТ'">
            <!-- <slot>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated(this.tablesList.sumFot + (this.tablesList.sumFot *0.051) + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 2.9)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)) ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot>
            ₽
          </td>
          <!-- <td class="tg-0lax" v-if="this.tablesList.systemTax == 'nds20'">
            20%
          </td> -->

          <td class="tg-0lax"
            v-if="(this.tablesList.name == 'ПАТЕНТ')&&(this.tablesList.systemTax == 'nds20')">
            <!-- <slot>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 1.8)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)))* 0.2 ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumWithoutNdc * 0.2) }}</slot>
            ₽
          </td>
          <td class="tg-0lax"
            v-if="(this.tablesList.name !== 'ПАТЕНТ')&&(this.tablesList.systemTax == 'nds20')">
            <!-- <slot>{{ formated(this.tablesList.sumSumWithoutNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot + (this.tablesList.sumFot *0.051) + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 2.9)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)))* 0.2 ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumWithoutNdc * 0.2) }}</slot>
            ₽
          </td>

          <td
            class="tg-0lax"
            v-if="(this.tablesList.systemTax == 'nds20')&&(this.tablesList.name == 'ПАТЕНТ')"
          >
            <!-- <slot>{{ formated(this.tablesList.sumSumAndNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 1.8)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)))*1.2 ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumAndNdc) }}</slot>
            ₽
          </td>
          <td
            class="tg-0lax"
            v-if="(this.tablesList.systemTax == 'nds20')&&(this.tablesList.name !== 'ПАТЕНТ')"
          >
            <!-- <slot>{{ formated(this.tablesList.sumSumAndNdc) }}</slot> -->
            <slot v-if="this.tablesList.fot < 15278">{{ formated((this.tablesList.sumFot + (this.tablesList.sumFot *0.051) + (this.tablesList.sumFot *0.22)+(this.tablesList.sumFot / 100 * 2.9)+this.tablesList.sumFssTrauma+(Number(this.tablesList.outstaffing) * Number(this.tablesList.countEmpol)))*1.2 ) }}</slot>
            <slot v-else>{{ formated(this.tablesList.sumSumAndNdc) }}</slot>
            ₽
          </td>
          <td class="tg-0lax" >
            <slot>{{ formated(this.tablesList.sumSalaries) }}</slot>
            ₽
          </td>
        </tr>

        <!-- <tr>
          <td
            class="tg-0lax"
            rowspan="4"
            v-if="this.tablesList.systemTax == 'nds20'"
          >
            <slot>{{ formated(this.tablesList.sumNdc) }}</slot>
            ₽
          </td>
        </tr> -->

        </tbody>
        <div class="" style="color:red;    min-width: 410px; margin-top: 20px;">
          * Налоговая база рассчитана с использованием льгот
        </div>
      </table>





    <table class="tg">
    <thead>
      <tr>
        <th class="tg-bobw" colspan="4">
          <span style="font-weight: bold">ИТОГОВЫЙ РАСЧЕТ:</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="tg-a08a">
          <span style="background-color: #f8cbad">Статус сотрудника </span>
        </td>
        <td class="tg-s4mc" v-if="Object.keys(this.tablesList.name).length">
          <span style="font-weight: normal; background-color: #f8cbad"
            >{{this.tablesList.name}}</span
          >
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Ставка аутстаффинга</span>
        </td>
        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outstaffing) }}</slot> ₽</span
          >
        </td>

      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">ФОТ (Регламент)</span>
        </td>
        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.fot) }}</slot> ₽</span
          >
        </td>
      </tr>

      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Кол-во отработанных часов в месяц</span
          >
        </td>
        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{
              this.tablesList.hours * this.tablesList.days
            }}</slot></span
          >
        </td>

      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold">Человека час ТД</span>
        </td>
        <td class="tg-2b7s">
          <span v-if="(this.tablesList.fot /
        (this.tablesList.hours * this.tablesList.days) > 143)" style="font-weight: normal">{{ formated(this.tablesList.fot /
        (this.tablesList.hours * this.tablesList.days)) }} ₽</span>
        <span v-else style="font-weight: normal">{{ 143 }} ₽</span>
        </td>

      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Человека час для клиента (без НДС)</span
          >
        </td>

        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.tablesList.sumSumWithoutNdc /
                  (this.tablesList.hours * this.tablesList.days)
              )
            }}</slot>
            ₽</span
          >
        </td>
      </tr>
      <tr v-if="this.tablesList.systemTax == 'nds20'">
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Человека час для клиента (с НДС)</span
          >
        </td>
        <td class="tg-2b7s" >
          <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.tablesList.sumAndNdc /
                  (this.tablesList.hours * this.tablesList.days)
              )
            }}</slot>
            ₽</span
          >
        </td>
      </tr>
      <tr style="visibility: hidden;">
        qwe
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Вознаграждение менеджера с человека</span
          >
        </td>
        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{ formated(this.tablesList.outstaffing * 0.1) }}</slot>
            ₽</span
          >
        </td>
      </tr>
      <tr>
        <td class="tg-j6zm">
          <span style="font-weight: bold"
            >Вознаграждение менеджера с оборота</span
          >
        </td>
        <td class="tg-2b7s">
          <span style="font-weight: normal"
            ><slot>{{
              formated(
                this.tablesList.outstaffing * 0.1 * this.tablesList.countEmpol
              )
            }}</slot>
            ₽</span
          >
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "table-vue",
  props: ["tablesList"],
  data() {
    return {
      fssProc: "1.80%",
      CONSTS: {
        FOT_FIX: 15279,
        NDFL: 0.13,
        NDS: 0.2,
        PFR_FIX: 0.22,
        PFR_REMAINDER: 0.1,
        FSS_INSURANCE: 0.018,
        FSS_OTHER: 0.029,
        FFOMC_FIX: 0.051,
        FFOMC_REMAINDER: 0.05,
      },
      tableListPatent: {},
      tableListEaeu: {},
      tableListRf: {},
      reglament: 0,
      reglamentRemain: 0,
      ffomc: 0,
      ffomcRemained: 0,
      fssC: 0,
      fssTrauma: 0,
      tax: 0,
      sumWithoutNdc: 0,
      ndc: 0,
      sumAndNdc: 0,
      emloyTd: 0,
      emloyTdMin: false,
      systemTax: null,
    };
  },

  methods: {
    checkFssProc() {
      if (this.tablesList.name !== "ПАТЕНТ") {
        this.fssProc = "2.90%";
      }
      if (this.tablesList.name == "ПАТЕНТ") {
        this.fssProc = "1.80%";
      }
    },
    getInnerHTML(table) {
      return table;
    },
    formated(value) {
      let formatter = new Intl.NumberFormat("ru", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },

  calc() {
      this.emloyTd = (
        this.tablesList.fot /
        (this.tablesList.hours * this.tablesList.days)
      ).toFixed(2);

      if (Number(this.emloyTd) <= 143) {
        this.emloyTdMin = true;
        this.emloyTd = 143;
      } else if (Number(this.emloyTd) > 143) {
        this.emloyTdMin = false;
      }
    },
  mounted() {
    this.$nextTick(function () {
      this.checkFssProc();
    });
  },
};
</script>

<style>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  overflow: hidden;
  padding: 20px 10px !important;
  word-break: normal;
}
.tg th {
  border-color: black;
  border-style: solid;
  border-width: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 10px 5px !important;
  word-break: normal;
}
.tg .tg-y21g {
  background-color: #3d5afe;
  background-color: #a2cf6e;
  text-align: center;
  vertical-align: center;
}
.tg .tg-0lax {
  text-align: center;
  vertical-align: center;
}

.tg-hor{
  table-layout: fixed;
  width: 100%;
  margin-bottom: 50px;
}

@media screen and (max-width: 768px) {
  .tg-hor{
    table-layout: fixed;
    margin-bottom: 50px;

    width: 200%;
  }
}
</style>
