<template>
  <div style="display: none;" class="left_block">
    <div class="left_block__inner">
      <h3>Колличество часов в смену</h3>
      <div class="checkbox_wrapper amound">
        <amount-hours-patent-vue
          :isDisableFotPatent="isDisableFot.isDisableFotPatent"
          @updatePatent="updateCountHoursPatent"
          ref="amountHoursPatent"
        />
        <amount-hours-eaeu-vue
          :isDisableFotEaeu="isDisableFot.isDisableFotEaeu"
          @updateEaeu="updateCountHoursEaeu"
          ref="amountHoursEaeu"
        />
        <amount-hours-rf-vue
          :isDisableFotRf="isDisableFot.isDisableFotRf"
          @updateRf="updateCountHoursRf"
          ref="amountHoursRf"
        />
      </div>
      <div class="important_info">Укажите кол-во часов в смену</div>
    </div>
  </div>
</template>

<script>
import AmountHoursEaeuVue from "./AmountHours-eaeu.vue";
import AmountHoursPatentVue from "./AmountHours-patent.vue";
import AmountHoursRfVue from "./AmountHours-rf.vue";

export default {
  props: {
    isDisableFot: {
      type: Object,
    },
  },

  components: {
    AmountHoursEaeuVue,
    AmountHoursPatentVue,
    AmountHoursRfVue,
  },

  data() {
    return {
      countHours: {
        countPatent: 8,
        countEaeu: 8,
        countRf: 8,
      },
    };
  },

  methods: {
    updateCountHoursPatent(hours) {
      this.countHours.countPatent = hours;
      this.$emit("upHours", this.countHours);
    },
    updateCountHoursEaeu(hours) {
      this.countHours.countEaeu = hours;
      this.$emit("upHours", this.countHours);
    },
    updateCountHoursRf(hours) {
      this.countHours.countRf = hours;
      this.$emit("upHours", this.countHours);
    },

    resetValues() {
      this.$refs.amountHoursPatent.resetValue();
      this.$refs.amountHoursEaeu.resetValue();
      this.$refs.amountHoursRf.resetValue();
    },
  },
};
</script>

<style></style>
