<template>
  <div class="left_block" style="border: none;">
    <div class="left_block__inner">
      <h3>Укажите график работы</h3>
      <div class="checkbox_wrapper amound" style="display: grid;grid-template-rows: 1fr 1fr 1fr; justify-content: center;">
        <amount-days-patent-vue
          :isDisableFotPatent="isDisableFot.isDisableFotPatent"
          @updatePatent="updateCountDaysPatent"
          ref="amountDaysPatent"
        />
        <amount-days-eaeu-vue
          :isDisableFotEaeu="isDisableFot.isDisableFotEaeu"
          @updateEaeu="updateCountDaysEaeu"
          ref="amountDaysEaeu"
        />
        <amount-days-rf-vue
          :isDisableFotRf="isDisableFot.isDisableFotRf"
          @updateRf="updateCountDaysRf"
          ref="amountDaysRf"
        />
      </div>
      <div class="important_info"><p>В среднем, при графике 5/2 - 22 рабочие смены по 8 часов,</p><p>при графике 6/1 - 26 рабочих смен по 12 часов,</p><p>при графике 2/2 - 15 рабочих смен по 12 часов.</p></div>
    </div>
  </div>
</template>

<script>
import AmountDaysEaeuVue from "./AmountDays-eaeu.vue";
import AmountDaysPatentVue from "./AmountDays-patent.vue";
import AmountDaysRfVue from "./AmountDays-rf.vue";

export default {
  props: {
    isDisableFot: {
      type: Object,
    },
  },

  components: {
    AmountDaysEaeuVue,
    AmountDaysPatentVue,
    AmountDaysRfVue,
  },

  data() {
    return {
      countDays: {
        countPatent: 22,
        countEaeu: 22,
        countRf: 22,
      },
    };
  },

  methods: {
    updateCountDaysPatent(days) {
      this.countDays.countPatent = days;
      this.$emit("upDays", this.countDays);
    },
    updateCountDaysEaeu(days) {
      this.countDays.countEaeu = days;
      this.$emit("upDays", this.countDays);
    },
    updateCountDaysRf(days) {
      this.countDays.countRf = days;
      this.$emit("upDays", this.countDays);
    },

    resetValues() {
      this.$refs.amountDaysPatent.resetValue();
      this.$refs.amountDaysEaeu.resetValue();
      this.$refs.amountDaysRf.resetValue();
    },
  },
};
</script>

<style></style>
