<template>
  <div class="form_block">
    <form action="">
      <div class="calculate_content">
        <div class="left ">
          <genefal-info-inputs-vue @upInfo="upInfo" ref="generalInfo" />
          <profession-form-vue
            @addProfession="addProfession"
            @upPopInfo="$emit('upPopInfo')"
            ref="professionForm"
          />
          <div class="outsorse-wrapper">
            <proffession-list-vue :professionList="professionList"
            class="profflist"/>
          
           
         </div>
         
            <submit-reset-vue
              @calc="calculate"
              @reset="reset"
              :type="'outsource'"
            />
        </div>
      </div>
    </form>
    <div class="error error-mrot popErr" v-if="this.isShow">
      Необходимо добавить профессию, чтобы произвести рассчеты
    </div>
  </div>
</template>

<script>
import GenefalInfoInputsVue from "../Fields/GeneralInfo-inputs/GeneralInfo-inputs.vue";
import ProfessionFormVue from "./ProfessionForm.vue";
import ProffessionListVue from "./ProfessionList.vue";

import SubmitResetVue from "../Fields/Submit/SubmitReset.vue";

export default {
  components: {
    GenefalInfoInputsVue,
    ProfessionFormVue,
    ProffessionListVue,
    
    SubmitResetVue,
  },
  data() {
    return {
      heading: "Уровень ФОТ (после вычета налога)",
      name: "Не указано",
      project: "Не указано",
      fieldOfActivity: "Не указано",
      outsource: 0,
      fss: 0,
      professionList: [],
      professionListBuffer: [],
      professionItem: {},
      isShow: false,
    };
  },

  methods: {
    addProfession(proffessionItem) {
      var fssCheck = document.getElementById("fssInput");
        if (fssCheck !== null && fssCheck.value === "")
        {
          alert('Заполните поле "ФСС Травма" для того, чтобы добавить профессию!');
        } else {
        

        this.$emit("addProfessionList", this.professionList);
          this.professionItem = JSON.parse(JSON.stringify(proffessionItem));
          this.professionList.push(this.professionItem);
          this.professionItem = {};
      }
    },
    upInfo(info) {
      this.name = info.companyName;
      this.project = info.projectName;
      this.fieldOfActivity = info.scopeName;
    },

    upOutsource(outsource) {
      this.outsource = outsource;
    },
    upFss(fss) {
      this.fss = fss;
    },

    getProfessionList(professionList) {
      this.professionList = professionList;
    },

    calculate() {
      if (this.professionList.length) {
        this.$emit("upProfessionList", {
          name: this.name,
          project: this.project,
          fieldOfActivity: this.fieldOfActivity,
          outsource: this.outsource,
          fss: this.fss,
          tablesList: this.professionList,
          isShow: this.professionList.length,
        });
      } else {
        this.isShow = true;
        setTimeout(() => {
          this.isShow = false;
        }, 3000);
        this.$refs.professionForm.$refs.proffessionInput.focusProffessionInput();
      }
    },
    reset() {
      this.$refs.outsourceInput.resetValue();
      this.$refs.fssInput.resetValue();
      this.$refs.professionForm.resetProfessionList();
      this.professionList = [];

      this.$emit("upProfessionList", {
        name: this.name,
        project: this.project,
        fieldOfActivity: this.fieldOfActivity,
        outsource: this.outsource,
        fss: this.fss,
        tablesList: [],
        isShow: this.professionList.length,
      });

      this.$refs.generalInfo.resetGeneralInfo();
    },
  },
};
</script>

<style>
.outsorse-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
  }

  .profflist{
    position: absolute;
    border: none;
    right: 0;
    top: -150px;
  }

  .outsorse-wrapper__extra{
    position: absolute;

    top: 50%;
  }

  .form_block .left{
    position: relative;
  }
  @media screen and (max-width: 768px) {
  .outsorse-wrapper{
    display: block;
  }

  .outsorse-wrapper__extra{
    position: relative;

    top: none;
  }

  .profflist{
    display: block;
    position: relative;
  }

  .outstaffing_calculator .tableSmallWrap{
    justify-content: left !important;
  }
}

</style>
