<template>
  <label class="input_calc">
    <!-- <input
      type="number"
      min="0"
      v-model="countRf"
      @input="updateValue"
      :disabled="isDisableFotRf"
      required
      placeholder="0"
      class="sum-staff__rf"
    /> -->
    <span>РФ</span>
    <select required v-model="countRf" @change="updateValue" :disabled="isDisableFotRf">
        <option disabled value="">График работы</option>
        <option value=22>5/2</option>
        <option value=26>6/1</option>
        <option value=15>2/2</option>
      </select>
    
    <span class="error"></span>
  </label>
</template>

<script>
export default {
  props: {
    isDisableFotRf: {
      type: Boolean,
    },
  },
  data() {
    return {
      countRf: 22,
    };
  },

  methods: {
    updateValue() {
      this.$emit("updateRf", this.countRf);
    },
    resetValue() {
      this.countRf = 22;
      this.$emit("updateRf", this.countRf);
    },
  },
};
</script>

<style>
select{
  font-size: 21px;
  width: 108px;
  height: 50px;
}
</style>
