<template>
  <div class="outsorse-wrapper">
    <amount-emoloyees-inputs-vue
      @upCountEmployees="upCountEmployees"
      ref="amountEmployess"
    />

    <fot-inputs-vue
      :isDisableFot="this.proffessionItem.isDisableFot"
      @upFot="upFot"
      :heading="heading"
      ref="fotInputs"
    />

    <amount-hours-inputs-vue
      :isDisableFot="this.proffessionItem.isDisableFot"
      @upHours="upHours"
      ref="amountHours"
    />
    <amount-days-inputs-vue
      :isDisableFot="this.proffessionItem.isDisableFot"
      @upDays="upDays"
      ref="amountDays"
    />

    <outsource-input-vue @upOutsource="upOutsource" ref="outsourceInput" />

    <fss-input-vue @upFss="upFss" ref="fssInput" />
    <div class="addProfession-btn__wrap">
      <profession-input-vue
        @upProfession="upProfession"
        ref="proffessionInput"
      />
      <button
        @click.prevent="addProfession"
        class="addProfession-btn outsource-bg"
      >
        Добавить профессию
      </button>
    </div>
    <div
      class="error error-mrot"
      v-if="this.isShow"
      :class="{ popErr: popErr, popSuccess: !popErr }"
    >
      {{ popMessage }}
    </div>
  </div>
</template>

<script>
import ProfessionInputVue from "../Fields/Profession/Profession-input.vue";
import AmountEmoloyeesInputsVue from "../Fields/AmountEmployees-inputs/AmountEmployees-inputs.vue";
import FotInputsVue from "../Fields/Fot/Fot-inputs.vue";
import AmountHoursInputsVue from "../Fields/AmountHours-inputs/AmountHours-inputs.vue";
import AmountDaysInputsVue from "../Fields/AmountDays-inputs/AmountDays-inputs.vue";
import OutsourceInputVue from "../Fields/Outsource/Outstource-input.vue";
import FssInputVue from "../Fields/Fss/Fss-input.vue";

export default {
  components: {
    ProfessionInputVue,
    AmountEmoloyeesInputsVue,
    FotInputsVue,
    AmountHoursInputsVue,
    AmountDaysInputsVue,
    OutsourceInputVue,
    FssInputVue,
  },

  data() {
    return {
      heading: "Заработная плата сотрудника",
      popMessage: "",
      popErr: true,
      proffessionList: [],
      proffessionItem: {
        profession: "",
        proffestionEmploy: [],
        countEmployees: {
          countPatent: 1,
          countEaeu: 1,
          countRf: 1,
        },
        isDisableFot: {
          isDisableFotPatent: false,
          isDisableFotEaeu: false,
          isDisableFotRf: false,
        },
        fot: {
          countPatent: 0,
          countEaeu: 0,
          countRf: 0,
        },
        hours: {
          countPatent: 8,
          countEaeu: 8,
          countRf: 8,
        },
        days: {
          countPatent: 22,
          countEaeu: 22,
          countRf: 22,
        },
        outsource: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
        fss: {
          patent: 0,
          eaeu: 0,
          rf: 0,
        },
      },
      isShow: false,
    };
  },
  //  &&
  //       (!this.proffessionItem.isDisableFot.isDisableFotRf || this.proffessionItem.fot.countRf >= 25500)
  methods: {
    addProfession() {
      console.log(this.proffessionList);
      if (
        this.proffessionItem.profession &&
        (this.proffessionItem.countEmployees.countPatent > 0 ||
          this.proffessionItem.countEmployees.countEaeu > 0 ||
          this.proffessionItem.countEmployees.countRf > 0)
      ) {
        if (!this.proffessionItem.isDisableFot.isDisableFotPatent) {
          if (!this.proffessionItem.isDisableFot.isDisableFotEaeu) {
            if (!this.proffessionItem.isDisableFot.isDisableFotRf) {
              if (
                this.proffessionItem.fot.countPatent > 25499 &&
                this.proffessionItem.fot.countEaeu > 25499 &&
                this.proffessionItem.fot.countRf > 25499
              ) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              } else {
                alert(
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
                );
                this.popErr = true;
                this.popMessage =
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
              }
            } else if (
              this.proffessionItem.fot.countPatent > 25499 &&
              this.proffessionItem.fot.countEaeu > 25499
            ) {
              this.pushTableArray();
              this.proffessionList.push(this.proffessionItem);
              this.popErr = false;
              this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
              this.isShow = true;
              setTimeout(() => {
                this.isShow = false;
              }, 3000);
              this.$emit("addProfession", this.proffessionItem);
            } else {
              alert(
                "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
              );
              this.popErr = true;
              this.popMessage =
                "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
              this.isShow = true;
              setTimeout(() => {
                this.isShow = false;
              }, 3000);
            }
          } else {
            if (!this.proffessionItem.isDisableFot.isDisableFotRf) {
              if (
                this.proffessionItem.fot.countPatent > 25499 &&
                this.proffessionItem.fot.countRf > 25499
              ) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              }
            } else {
              if (this.proffessionItem.fot.countPatent > 25499) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              } else {
                alert(
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
                );
                this.popErr = true;
                this.popMessage =
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
              }
            }
          }
        } else {
          if (!this.proffessionItem.isDisableFot.isDisableFotEaeu) {
            if (!this.proffessionItem.isDisableFot.isDisableFotRf) {
              if (
                this.proffessionItem.fot.countEaeu > 25499 &&
                this.proffessionItem.fot.countRf > 25499
              ) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              }
            } else {
              if (this.proffessionItem.fot.countEaeu > 25499) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              } else {
                alert(
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
                );
                this.popErr = true;
                this.popMessage =
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
              }
            }
          } else {
            if (!this.proffessionItem.isDisableFot.isDisableFotRf) {
              if (this.proffessionItem.fot.countRf > 25499) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              } else {
                alert(
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
                );
                this.popErr = true;
                this.popMessage =
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
              }
            } else {
              if (this.proffessionItem.fot.countPatent > 25499) {
                this.pushTableArray();
                this.proffessionList.push(this.proffessionItem);
                this.popErr = false;
                this.popMessage = `Профессия ${this.proffessionItem.profession} успешно добавлена`;
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
                this.$emit("addProfession", this.proffessionItem);
              } else {
                alert(
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб"
                );
                this.popErr = true;
                this.popMessage =
                  "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
                this.isShow = true;
                setTimeout(() => {
                  this.isShow = false;
                }, 3000);
              }
            }
          }
          this.popErr = true;
          this.popMessage =
            "Заработная плата сотрудника, не должна быть меньше 25 500 руб";
          this.isShow = true;
          setTimeout(() => {
            this.isShow = false;
          }, 3000);
          // this.$refs.proffessionInput.focusProffessionInput();
        }
      } else {
        this.popErr = true;
        this.popMessage =
          'Заполните поле "Профессия" и поле "Количество сотрудников", чтобы добавить профессию';
        this.isShow = true;
        setTimeout(() => {
          this.isShow = false;
        }, 3000);
        this.$refs.proffessionInput.focusProffessionInput();
      }
    },
    getProfessionList() {
      this.$emit("addProfession", this.proffessionList);
    },

    resetProfessionList() {
      this.proffessionList = [];
      this.$refs.proffessionInput.resetInput();
      this.$refs.fotInputs.resetValues();
      this.$refs.amountDays.resetValues();
      this.$refs.amountHours.resetValues();
      this.$refs.amountEmployess.resetValuesOutsource();
    },
    pushTableArray() {
      this.proffessionItem.proffestionEmploy.length = 0;
      if (this.proffessionItem.countEmployees.countPatent) {
        let table = {
          name: "ПАТЕНТ",
          countEmpol: this.proffessionItem.countEmployees.countPatent,
          proffession: this.proffessionItem.profession,
          fot: this.proffessionItem.fot.countPatent,
          hours: this.proffessionItem.hours.countPatent,
          days: this.proffessionItem.days.countPatent,
        };
        this.proffessionItem.proffestionEmploy.push(table);
      }
      if (this.proffessionItem.countEmployees.countEaeu) {
        let table = {
          name: "ЕАЭС",
          countEmpol: this.proffessionItem.countEmployees.countEaeu,
          proffession: this.proffessionItem.profession,
          fot: this.proffessionItem.fot.countEaeu,
          hours: this.proffessionItem.hours.countEaeu,
          days: this.proffessionItem.days.countEaeu,
        };
        this.proffessionItem.proffestionEmploy.push(table);
      }
      if (this.proffessionItem.countEmployees.countRf) {
        let table = {
          name: "РФ",
          countEmpol: this.proffessionItem.countEmployees.countRf,
          proffession: this.proffessionItem.profession,
          fot: this.proffessionItem.fot.countRf,
          hours: this.proffessionItem.hours.countRf,
          days: this.proffessionItem.days.countRf,
        };
        this.proffessionItem.proffestionEmploy.push(table);
      }
    },
    focusProffessionInput() {},
    upProfession(profession) {
      this.proffessionItem.profession = profession;
    },
    upCountEmployees(countEmployees) {
      this.proffessionItem.countEmployees.countPatent =
        countEmployees.countPatent;
      this.proffessionItem.countEmployees.countEaeu = countEmployees.countEaeu;
      this.proffessionItem.countEmployees.countRf = countEmployees.countRf;
    },
    upFot(fot) {
      this.proffessionItem.fot.countPatent = fot.patent;
      this.proffessionItem.fot.countEaeu = fot.eaeu;
      this.proffessionItem.fot.countRf = fot.rf;
    },
    upHours(hours) {
      this.proffessionItem.hours.countPatent = hours.countPatent;
      this.proffessionItem.hours.countEaeu = hours.countEaeu;
      this.proffessionItem.hours.countRf = hours.countRf;
    },
    upDays(days) {
      this.proffessionItem.days.countPatent = days.countPatent;
      this.proffessionItem.days.countEaeu = days.countEaeu;
      this.proffessionItem.days.countRf = days.countRf;
    },
    upOutsource(outsource) {
      this.proffessionItem.outsource.patent = outsource;
      this.proffessionItem.outsource.eaeu = outsource;
      this.proffessionItem.outsource.rf = outsource;
    },
    upFss(fss) {
      this.proffessionItem.fss.patent = fss;
      this.proffessionItem.fss.eaeu = fss;
      this.proffessionItem.fss.rf = fss;
    },
  },
  watch: {
    proffessionItem: {
      deep: true,
      handler(newValue) {
        if (newValue.countEmployees.countPatent == 0) {
          this.proffessionItem.isDisableFot.isDisableFotPatent = true;
        } else if (newValue.countEmployees.countPatent > 0) {
          this.proffessionItem.isDisableFot.isDisableFotPatent = false;
          // this.$emit("upPopInfo", false);
        }
        if (newValue.countEmployees.countEaeu == 0) {
          this.proffessionItem.isDisableFot.isDisableFotEaeu = true;
        } else if (newValue.countEmployees.countEaeu > 0) {
          this.proffessionItem.isDisableFot.isDisableFotEaeu = false;
          // this.$emit("upPopInfo", false);
        }
        if (newValue.countEmployees.countRf == 0) {
          this.proffessionItem.isDisableFot.isDisableFotRf = true;
        } else if (newValue.countEmployees.countRf > 0) {
          this.proffessionItem.isDisableFot.isDisableFotRf = false;
          // this.$emit("upPopInfo", false);
        }
      },
    },
  },

  beforeMount() {
    this.$nextTick(function () {
      this.$refs.amountEmployess.initialValueOutsource();
    });
  },
};
</script>

<style>
.addProfession-btn__wrap {
  display: flex;
  justify-content: start;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 4;
}
.addProfession-btn {
  max-width: 100%;
  padding: 15px 10px;
  max-width: 300px;
  width: 100%;
  color: white;
  height: 74px;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
}

.popErr {
  color: red;
}
.popSuccess {
  color: green;
}

@media screen and (max-width: 768px) {
  .addProfession-btn__wrap {
    display: flex;
    flex-direction: column;
    align-items: start;

    margin-top: 0;
  }
  .addProfession-btn {
    margin-left: 20px;
  }
}
</style>
